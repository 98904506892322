import { buildCsvExportInputs, buildCsvTitlesRow } from './csv-export-utils';
// import { Col, Grid } from '@zing/neo-common/dist/components/Grid';
import { connect } from 'react-redux';
import { CSVLink } from 'react-csv';
import { csvExportMachine } from '../../../machines/csv-export.machine';
import { toFixed } from '@zing/neo-common/dist/lib/safe/to-fixed';
import { useMachine } from '@xstate/react';
import Button from 'hollywood/dist/components/Button';
import { Col, Grid } from '@zing/neo-common/dist/components/Grid';
import pimAccessor from '@zing/neo-common/dist/accessors/pim';
import PimComparison, { BATTERY_ONLY_PACKAGE_PROD_ID } from '@zing/neo-common/dist/accessors/pim-comparison';
import PIMInfo from '../../../components/pim-info';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import './csv-export.scss';
import DiagnosticsHeader from '../../../components/diagnostics-header';
import { Formik, Form } from 'formik';
import Select from 'hollywood/dist/components/forms/Select/Select';
import CheckboxGroup from 'hollywood/dist/components/forms/CheckboxGroup/CheckboxGroup';
import FieldError from 'hollywood/dist/components/forms/FieldError/FieldError';
import { RESIDENTIAL_CUSTOMER, customerTypesAllowedInApp } from '@zing/neo-common/dist/config';
import pimProductTypes from '@zing/neo-common/dist/accessors/pim-product-types';
import NumberInput from 'hollywood/dist/components/forms/NumberInput/NumberInput';
import Input from 'hollywood/dist/components/forms/Input/Input';

/**
 * As the manager of the pricing in the app, i would like to be able to run a suite of tests on UAT before
 * pushing new product data live, to check that the pricing is correct, consistent across the portfolio, and with no
 * unexpected anomalies.
 *
 * e.g. calculate (cost, ex-VAT and inc-VAT prices) for
 *  - Each package
 *    - with/without (2.6, 5.2, 8.2 batteries)
 *      - With 2..40 panels
 *
 *  > If the list of "products" could also be spat out, that would also be a rather useful check.
 *
 * @param product
 * @returns {*}
 * @constructor
 */

// temp function to reduce dataset during dev
// const simplifyDuringDev = pricingModels => [
//   // pricingModels[0],
//   pricingModels[200],
//   pricingModels[400],
//   pricingModels[800],
//   pricingModels[1200],
// ];
const simplifyDuringDev = x => x;

const INITIAL_VALUES = {
  pkg: '',
  panels: [],
  roofFixing: '',
  batteries: [],
  scaffolding: '',
  leadCategory: RESIDENTIAL_CUSTOMER,
  discountType: 'none',
  discount: 0,
  discountCode: '',
};

function validate(values) {
  const errors = {};
  if (!values.pkg) {
    errors.pkg = 'Please select a package';
  }

  if (!values.leadCategory) {
    errors.leadCategory = 'Please select the customer type';
  }
  if (!values.discountType) {
    errors.discountType = 'Please select a discount type';
  }
  if (values.pkg !== BATTERY_ONLY_PACKAGE_PROD_ID && !values.scaffolding) {
    errors.scaffolding = 'Please select a scaffolding option';
  }

  return errors;
}

const CostMatricesPage = ({ pimStore }) => {
  const [current, send] = useMachine(csvExportMachine);
  const pim = pimAccessor(pimStore);
  const comparison = useMemo(() => new PimComparison(pim), []); // dont recreate the comparison on every render!
  const packages = pim.packages();

  const headers = buildCsvTitlesRow();

  const discountProducts = pim.filterByType(pim.products(), pimProductTypes.eOnDiscountOrOffer);

  return (
    <>
      <DiagnosticsHeader title="CSV Export" />
      <main className="diagnostics">
        <PIMInfo pim={pimStore} />

        <Formik
          initialValues={INITIAL_VALUES}
          validate={validate}
          onSubmit={values => {
            const selectedPackage = packages.find(x => x.prodId === values.pkg);
            const pricingModels = simplifyDuringDev(
              buildCsvExportInputs(
                {
                  pkg: selectedPackage,
                  panels: values.panels,
                  batteries: values.batteries,
                  scaffolding: values.scaffolding,
                  roofFixing: values.roofFixing,
                  leadCategory: values.leadCategory,
                  discountType: values.discountType,
                  discount: values.discount,
                  discountCode: values.discountCode,
                },
                pim
              )
            );
            send({
              type: 'LOAD',
              pricingModels,
              pim,
            });
            send({ type: 'PROCESS_ROW' });
          }}
        >
          {({ values, setFieldValue, resetForm }) => (
            <Form>
              <div className="form-field">
                <h3 className="tight">
                  <label htmlFor="leadCategory">Customer type</label>
                </h3>
                <Select name="leadCategory" id="leadCategory" testId="leadCategory" options={customerTypesAllowedInApp} />
                <FieldError field="leadCategory" />
              </div>
              <div className="form-field">
                <h3 className="tight">
                  <label htmlFor="pkg">Package</label>
                </h3>
                <Select
                  name="pkg"
                  id="pkg"
                  testId="pkg"
                  options={packages
                    .map(p => ({ label: `${p.prodId} - ${p.name}`, value: p.prodId }))
                    .sort((a, b) => a.value.localeCompare(b.value))}
                  onChange={(e, val) => {
                    const { leadCategory } = values;
                    resetForm();
                    setFieldValue('leadCategory', leadCategory);
                    setFieldValue('pkg', val);
                    if (val !== BATTERY_ONLY_PACKAGE_PROD_ID) {
                      setFieldValue('scaffolding', 'SCAFF-2S-1F');
                    }
                    comparison.setPackage(val);
                  }}
                />
                <FieldError field="pkg" />
              </div>
              {values.pkg && values.pkg !== BATTERY_ONLY_PACKAGE_PROD_ID && (
                <>
                  <div className="form-field">
                    <h3 className="tight">
                      <label htmlFor="panels">Panels</label>
                    </h3>
                    <CheckboxGroup
                      name="panels"
                      id="panels"
                      testId="panels"
                      options={comparison
                        .getPanels()
                        .map(p => ({ label: `${p.prodId} - ${p.name}`, value: p.prodId }))
                        .sort((a, b) => a.value.localeCompare(b.value))}
                    />
                    <FieldError field="panels" />
                  </div>
                  <div className="form-field">
                    <h3 className="tight">
                      <label htmlFor="panels">Roof Fixings</label>
                    </h3>
                    <Select
                      name="roofFixing"
                      id="roofFixing"
                      testId="roofFixing"
                      options={comparison
                        .getRoofFixings()
                        .map(s => ({ label: s.name, value: s.prodId }))
                        .sort((a, b) => a.value.localeCompare(b.value))}
                    />
                    <FieldError field="panels" />
                  </div>
                  <div className="form-field">
                    <h3 className="tight">
                      <label htmlFor="scaffolding">Scaffolding</label>
                    </h3>
                    <Select
                      name="scaffolding"
                      id="scaffolding"
                      testId="scaffolding"
                      options={comparison
                        .getScaffolding()
                        .map(s => ({ label: s.prodId, value: s.prodId }))
                        .sort((a, b) => a.value.localeCompare(b.value))}
                    />
                    <FieldError field="scaffolding" />
                  </div>
                </>
              )}
              {values.pkg && (
                <>
                  <div className="form-field">
                    <Grid>
                      <Col>
                        <h3 className="tight">
                          <label htmlFor="battery">Batteries</label>
                        </h3>
                      </Col>
                      <Col width="auto">
                        <Button
                          format="secondary"
                          type="button"
                          onClick={() =>
                            setFieldValue(
                              'batteries',
                              comparison.getBatteries().map(b => b.prodId)
                            )
                          }
                        >
                          Select All
                        </Button>
                      </Col>
                    </Grid>
                    <CheckboxGroup
                      name="batteries"
                      id="batteries"
                      testId="batteries"
                      options={comparison
                        .getBatteries()
                        .map(p => ({ label: `${p.prodId} - ${p.name}`, value: p.prodId }))
                        .sort((a, b) => a.value.localeCompare(b.value))}
                    />
                    <FieldError field="batteries" />
                  </div>
                  <Grid gutters="md">
                    <Col>
                      <div className="form-field">
                        <h3 className="tight">
                          <label htmlFor="discountType">Discount type</label>
                        </h3>
                        <Select
                          name="discountType"
                          id="discountType"
                          testId="discountType"
                          options={[
                            { label: 'None', value: 'none' },
                            { label: 'Percentage', value: 'percentage' },
                            { label: 'Colleague', value: 'colleague' },
                            { label: 'Fixed', value: 'fixed' },
                            { label: 'Voucher Code', value: 'code' },
                          ]}
                          onChange={(_, value) => {
                            setFieldValue('discountCode', '');
                            if (value === 'colleague') {
                              setFieldValue('discount', 15);
                            } else {
                              setFieldValue('discount', 0);
                            }
                          }}
                        />
                        <FieldError field="discountType" />
                      </div>
                    </Col>
                    <Col>
                      {values.discountType === 'code' && (
                        <div className="form-field">
                          <h3 className="tight">
                            <label htmlFor="discountCode">Voucher Code:</label>
                          </h3>
                          <Select
                            name="discountCode"
                            id="discountCode"
                            testId="discountCode"
                            options={discountProducts.map(product => ({ label: product.name, value: product.prodId }))}
                          />
                        </div>
                      )}
                      {values.discountType === 'colleague' && (
                        <div className="form-field">
                          <h3 className="tight">
                            <label htmlFor="discount">Discount:</label>
                          </h3>
                          <Input type="number" name="discount" id="discount" testId="discountColleague" prefix="%" disabled />
                        </div>
                      )}
                      {values.discountType === 'percentage' && (
                        <div className="form-field">
                          <h3 className="tight">
                            <label htmlFor="discount">Discount:</label>
                          </h3>
                          <NumberInput name="discount" id="discount" testId="discountPercent" prefix="%" min="0" step="0.10" />
                        </div>
                      )}
                      {values.discountType === 'fixed' && (
                        <div className="form-field">
                          <h3 className="tight">
                            <label htmlFor="discount">Discount:</label>
                          </h3>
                          <Input name="discount" id="discount" testId="discountFixed" type="number" prefix="£" min={0} />
                        </div>
                      )}
                    </Col>
                  </Grid>
                </>
              )}

              {current.matches('idle') && (
                <Button format="primary" type="submit">
                  Begin CSV export
                </Button>
              )}
              {current.matches('generating') && (
                <div align="center" className="generating">
                  <div className="percentageDoneWrapper">
                    <div className="percentageDone" style={{ width: `${current.context.percentageComplete}%` }}>
                      {toFixed(current.context.percentageComplete, 0)}%
                    </div>
                  </div>
                  <div className="processing">
                    Processing {current.context.currentModelIndex} of {current.context.numModels}
                  </div>
                </div>
              )}
              {current.matches('generated') && (
                <CSVLink
                  headers={headers}
                  data={current.context.results}
                  filename="csv_export.csv"
                  className="hollywood-btn hollywood-btn--medium hollywood-btn--primary"
                  onClick={() => {
                    send({ type: 'RESET' });
                    resetForm();
                  }}
                >
                  Download CSV Export
                </CSVLink>
              )}
            </Form>
          )}
        </Formik>
      </main>
    </>
  );
};

CostMatricesPage.propTypes = {
  pimStore: PropTypes.shape({
    uuid: PropTypes.string,
    created_at: PropTypes.string,
  }).isRequired,
};

export default connect(({ pim }) => ({ pimStore: pim }))(CostMatricesPage);
