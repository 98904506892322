const { Machine } = require('xstate');

const settingsMachine = Machine({
  id: 'settings',
  initial: 'app',
  context: {},
  states: {
    app: {
      initial: 'offline',
      states: {
        offline: {
          on: {
            STATUS_ONLINE: 'online',
            SETTINGS_PROFILE: '#settings.profile.offline',
          },
        },
        online: {
          type: 'parallel',
          states: {
            refreshEmailTemplate: {
              initial: 'idle',
              states: {
                idle: {
                  on: {
                    REFRESH_EMAIL_TEMPLATE: 'fetch',
                  },
                },
                // Was happening too quickly, user needs to feel like it's doing something
                waiting: {
                  after: {
                    1500: 'idle',
                  },
                },
                fetch: {
                  invoke: {
                    id: 'refreshEmailTemplate',
                    src: (context, event) => event.exec(),
                    onDone: 'waiting',
                    onError: 'waiting',
                  },
                },
              },
            },
            refreshOpportunities: {
              initial: 'idle',
              states: {
                idle: {
                  on: {
                    REFRESH_OPPORTUNITIES: 'fetch',
                  },
                },
                fetch: {
                  invoke: {
                    id: 'refreshOpportunities',
                    src: (context, event) => event.exec(),
                    onDone: 'idle',
                    onError: 'idle',
                  },
                },
              },
            },
            refreshPimData: {
              initial: 'idle',
              states: {
                idle: {
                  on: {
                    REFRESH_PIM_DATA: 'fetch',
                  },
                },
                fetch: {
                  invoke: {
                    id: 'refreshPimData',
                    src: (context, event) => event.exec(),
                    onDone: 'idle',
                    onError: 'idle',
                  },
                },
              },
            },
            refreshPresenter: {
              initial: 'idle',
              states: {
                idle: {
                  on: {
                    REFRESH_PRESENTER: 'fetch',
                  },
                },
                fetch: {
                  invoke: {
                    id: 'refreshPresenter',
                    src: (context, event) => event.exec(),
                    onDone: 'idle',
                    onError: 'idle',
                  },
                },
              },
            },
          },
          on: {
            STATUS_OFFLINE: 'offline',
            SETTINGS_PROFILE: '#settings.profile.online',
          },
        },
      },
    },
    profile: {
      initial: 'offline',
      states: {
        offline: {
          on: {
            STATUS_ONLINE: 'online',
            SETTINGS_APP: '#settings.app.offline',
          },
        },
        online: {
          on: {
            STATUS_OFFLINE: 'offline',
            SETTINGS_APP: '#settings.app.online',
          },
        },
      },
    },
  },
});

export default settingsMachine;
