import React, { useContext } from 'react';
import Button from 'hollywood/dist/components/Button';
import FieldError from '../../hollywood-xstate/field-error';
import Icon from '@zing/neo-common/dist/components/Icon';
import NumberInput from '../../hollywood-xstate/number-input';
import Select from '../../hollywood-xstate/select';
import { Col, Grid } from '@zing/neo-common/dist/components/Grid';
import { PimSurveyMachineContext } from '../../contexts/survey-context';
import { poundsPence } from '@zing/neo-common/dist/lib/display';
import { getNumber } from '@zing/neo-common/dist/lib/safe/get-number';
import { createActiveRetiredProductsOptionList } from '../../utils/option-list-active-retired-products';

const MAX_NUM_ADDITIONAL_PRODUCTS = 7;

const AdditionalProducts = () => {
  const { current, send } = useContext(PimSurveyMachineContext);
  const { currentComparison, comparisons } = current.context;
  const { pimComparison, calcs = {} } = comparisons[currentComparison];

  const chosenAdditionalProducts = [...pimComparison.getAdditionalProducts()];
  const isolators = pimComparison.getIsolators();
  const birdDeterrents = pimComparison.getBirdGuards();
  const smartMeters = pimComparison.getSmartMeters();

  let rowsTotal = 0;

  const hasUnselectedItem = chosenAdditionalProducts.length > 0 && chosenAdditionalProducts.some(x => x.product === undefined);

  console.log('birdDeterrents', birdDeterrents);
  console.log('chosenAdditionalProducts', chosenAdditionalProducts);
  console.log('hasUnselectedItem', hasUnselectedItem);

  return (
    <>
      <div className="additional-products__headings">
        <Grid gutters="sm">
          <Col width="fixed" className="product-col">
            <strong>Select additional products</strong>
          </Col>
          <Col>
            <strong>Quantity</strong>
          </Col>
          <Col width="auto" className="range-right">
            <strong>Price</strong>
          </Col>
        </Grid>
      </div>

      {chosenAdditionalProducts.map((row, index) => {
        const productValuesFromCalcs = calcs?.values?.find(x => x?.product?.prodId === row?.product?.prodId);
        const priceWithVatIndividual = getNumber(productValuesFromCalcs, `priceWithVatIndividual`);
        const priceWithVatRow = getNumber(productValuesFromCalcs, `priceWithVatRow`);
        rowsTotal += priceWithVatRow;

        let options = [];
        let label;
        switch (index) {
          case 0:
            label = 'Smart Meter';
            options = createActiveRetiredProductsOptionList(smartMeters);
            break;
          case 1:
            label = 'Isolator';
            options = createActiveRetiredProductsOptionList(isolators);
            break;
          case 2:
            label = 'Bird Deterrent';
            options = createActiveRetiredProductsOptionList(birdDeterrents);
            break;
          default:
            options = createActiveRetiredProductsOptionList(
              // filter out any options from the dropdown that have already been selected
              pimComparison.getAddons().filter(addon => {
                // is it a selected product?
                if (chosenAdditionalProducts.find(x => x?.product?.prodId === addon.prodId)) {
                  // is it the current row?
                  return addon.prodId === row?.product?.prodId;
                }
                return true;
              })
            );

            break;
        }

        return (
          <div className="additional-products__row" key={row?.product?.prodId}>
            {label && (
              <label htmlFor={`additionalProducts[${index}]`}>
                <strong>{label}</strong>
              </label>
            )}
            <Grid gutters="sm" valign="center">
              <Col width="fixed" className="product-col">
                <Select
                  name={`additionalProducts[${index}]`}
                  value={row?.product?.prodId}
                  options={options}
                  onChange={(event, prodId) => {
                    if (prodId) {
                      send({ type: 'ADDITIONAL_PRODUCT_SELECT', currentComparison, prodId, index });
                    } else {
                      send({ type: 'ADDITIONAL_PRODUCT_UNSELECT', currentComparison, index });
                    }
                  }}
                />
              </Col>
              <Col width="auto">
                <NumberInput
                  disabled={row === undefined || index < 3}
                  min={1}
                  max={100}
                  value={row?.quantity}
                  type="number"
                  name={`additionalProducts[${index}].quantity`}
                  onChange={(event, value) => {
                    const quantity = Number(value);
                    send({ type: 'ADDITIONAL_PRODUCT_QUANTITY', currentComparison, quantity, index });
                  }}
                />
                <FieldError field={`additionalProducts[${index}].quantity`} />
              </Col>
              {index > 2 && (
                <Col width="auto">
                  <button
                    type="button"
                    data-testid={`deleteProduct${index}`}
                    onClick={() => {
                      send({ type: 'ADDITIONAL_PRODUCT_DELETE', currentComparison, index });
                    }}
                  >
                    <Icon icon="trash" className="light" />
                  </button>
                </Col>
              )}
              <Col className="range-right">
                <p className="tight light" data-testid={`priceWithVatIndividual_${index}`}>
                  Item price {poundsPence(priceWithVatIndividual)}
                </p>
                <p data-testid={`priceWithVatRow_${index}`}>
                  <strong>{poundsPence(priceWithVatRow)}</strong>
                </p>
              </Col>
            </Grid>
          </div>
        );
      })}

      <div className="additional-products__total">
        <Grid gutters="sm" valign="center">
          <Col width="auto">
            {chosenAdditionalProducts.length < MAX_NUM_ADDITIONAL_PRODUCTS &&
              chosenAdditionalProducts.length < pimComparison.getAddons().length + 3 && (
                <Button
                  testId="addProduct"
                  format="secondary"
                  name="addProductButton"
                  onClick={() => {
                    send({ type: 'ADDITIONAL_PRODUCT_ADD', currentComparison });
                  }}
                  disabled={hasUnselectedItem}
                >
                  <span className="plus">+</span> {chosenAdditionalProducts.length === 0 ? 'Add product' : 'Add another product'}
                </Button>
              )}
          </Col>
          <Col />
          <Col width="auto" className="range-right">
            <h3>Total</h3>
          </Col>
          <Col width="auto" className="range-right">
            <h3 data-testid="rows_total">{poundsPence(rowsTotal)}</h3>
          </Col>
        </Grid>
      </div>
    </>
  );
};

export default AdditionalProducts;
