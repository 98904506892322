import CsvExportPage from '../pages/diagnostics/pim/csv-export';
import CrmMessageRequestsPage from '../pages/diagnostics/crm/crm-message-requests';
import CrmMessageResponsesPage from '../pages/diagnostics/crm/crm-message-responses';
import OfflineQueuePage from '../pages/diagnostics/offline/offline-queue';
import PimVersionsPage from '../pages/diagnostics/pim/versions';
import PimPackagesPage from '../pages/diagnostics/pim/packages';
import PimProductsPage from '../pages/diagnostics/pim/products';
import UsersAdminPage from '../pages/diagnostics/admin/users';
import McsDataPage from '../pages/diagnostics/admin/mcs';
import VoucherCodesListPage from '../pages/diagnostics/voucher-codes/list';
import VoucherCodesAddPage from '../pages/diagnostics/voucher-codes/add';
import VoucherCodesEditPage from '../pages/diagnostics/voucher-codes/edit';
import VoucherCodesImportPage from '../pages/diagnostics/voucher-codes/import';

const diagnosticsSections = [
  {
    name: 'Admin',
    isAdminOnly: true,
    routes: [{ path: '/diagnostics/users', title: 'Users', component: UsersAdminPage, showInMenu: true }],
  },
  {
    name: 'CRM',
    isAdminOnly: true,
    routes: [
      {
        path: '/diagnostics/crm-message-requests',
        title: 'Message Requests',
        component: CrmMessageRequestsPage,
        showInMenu: true,
      },
      {
        path: '/diagnostics/crm-message-responses',
        title: 'Message Responses',
        component: CrmMessageResponsesPage,
        showInMenu: true,
      },
    ],
  },
  {
    name: 'Voucher Codes',
    isAdminOnly: true,
    routes: [
      { path: '/diagnostics/voucher-codes/list', title: 'View Codes', component: VoucherCodesListPage, exact: true, showInMenu: true },
      { path: '/diagnostics/voucher-codes/add', title: 'Add Codes', component: VoucherCodesAddPage, exact: true, showInMenu: true },
      {
        path: '/diagnostics/voucher-codes/import',
        title: 'Import Codes',
        component: VoucherCodesImportPage,
        exact: true,
        showInMenu: true,
      },
      {
        path: '/diagnostics/voucher-codes/:uuid',
        title: 'Edit Voucher Code',
        component: VoucherCodesEditPage,
        exact: true,
        showInMenu: false,
      },
    ],
  },
  {
    name: 'Offline',
    isAdminOnly: false,
    routes: [{ path: '/diagnostics/offline', title: 'Queue', component: OfflineQueuePage, showInMenu: true }],
  },
  {
    name: 'PIM',
    isAdminOnly: false,
    routes: [
      { path: '/diagnostics/pim/packages', title: 'Packages', component: PimPackagesPage, showInMenu: true },
      { path: '/diagnostics/pim/products', title: 'Products', component: PimProductsPage, showInMenu: true },
      { path: '/diagnostics/csv-export', title: 'CSV Export', component: CsvExportPage, showInMenu: true },
      { path: '/diagnostics/pim/versions', title: 'PIM Versions', component: PimVersionsPage, showInMenu: true },
    ],
  },
  {
    name: 'MCS',
    isAdminOnly: false,
    routes: [{ path: '/diagnostics/mcs', title: 'MCS Data', component: McsDataPage, showInMenu: true }],
  },
];

export default diagnosticsSections;
