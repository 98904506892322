/* eslint-disable prefer-promise-reject-errors */
import { Machine } from 'xstate';
// eslint-disable-next-line import/no-cycle
import surveyMachineOptions from './survey.machine.options';
import initialContext from './survey.machine.initial-context';
import axios from 'axios';
import { API_BASE_URL } from '../utils/paths';

export const getContextCurrentComparison = state => {
  const { currentComparison = '' } = state.context;
  return state?.context?.comparisons[currentComparison] || null;
};
export const getValueCurrentComparison = state => {
  const { currentComparison = '' } = state.context;
  return state?.value?.comparison[currentComparison] || null;
};

const condComparisonMatch = comparison => (_, { currentComparison }) => currentComparison === comparison;
const makeComparisonAction = (comparison, actions) => ({ actions, cond: condComparisonMatch(comparison) });

function uploadImage(currentComparison, index, file, appUuid, accessToken) {
  return new Promise((resolve, reject) => {
    // First validate the file size
    if (file.size / 1024 / 1024 > 1) {
      reject({ currentComparison, index, uploadError: `File is too large. Please select a file less than 2MB in size.` });
    }

    // Get the original extension
    const extension = file.name.split('.').pop();
    // Convert image to FormData to upload
    const data = new FormData();
    data.append('file', file, `${appUuid.replace(/-/g, '_')}-roofimage-${index + 1}.${extension}`);

    // Upload the image
    axios
      .post(`${API_BASE_URL}/quote/attachment/upload`, data, { headers: { Authorization: `Bearer ${accessToken}` } })
      .then(response => {
        resolve({ currentComparison, index, image: response.data.path });
      })
      .catch(error => {
        reject({ currentComparison, index, uploadError: `Sorry something went wrong - (${error.response.status}). Please try again.` });
      });
  });
}

const roofImageState = (comparison, roofId) => ({
  initial: 'idle',
  states: {
    idle: {
      on: {
        ROOF_IMAGE_UPLOAD: { target: 'uploading', cond: (context, event) => event.index === roofId },
      },
    },
    uploading: {
      entry: ['clearUploadImageErrorMessage'],
      invoke: {
        id: 'uploadImage',
        cond: (context, event) => event.index === roofId,
        src: (context, { currentComparison, index, file, appUuid, accessToken }) =>
          uploadImage(currentComparison, index, file, appUuid, accessToken),
        onDone: {
          target: 'success',
          actions: 'assignSaveUploadImageResponse',
        },
        onError: {
          target: 'failure',
          actions: 'assignUploadImageErrorMessage',
        },
      },
    },
    success: {
      on: {
        ROOF_IMAGE_DELETE: {
          target: 'idle',
          actions: 'assignDeleteRoofImage',
          cond: (context, event) => event.index === roofId,
        },
      },
    },
    failure: {
      on: {
        ROOF_IMAGE_UPLOAD: { target: 'uploading', cond: (context, event) => event.index === roofId },
      },
    },
  },
});

const comparisonState = comparison => ({
  type: 'parallel',
  states: {
    hist: {
      type: 'history',
      history: 'deep',
    },
    discount: {
      initial: 'none',
      states: {
        none: {},
        colleague: {},
        percentage: {
          on: {
            DISCOUNT_PERCENTAGE: { actions: ['assignDiscountPercentage', 'calcs'] },
          },
        },
        fixed: {
          on: {
            DISCOUNT_FIXED: { actions: ['assignDiscountFixed', 'calcs'] },
          },
        },
        code: {
          on: {
            DISCOUNT_CODE: { actions: ['assignDiscountCode', 'calcs'] },
          },
        },
      },
      on: {
        DISCOUNT_TYPE: [
          {
            target: '.none',
            actions: ['assignDiscountType', 'assignNoDiscountPercentage', 'assignNoDiscountFixed', 'assignNoDiscountCode', 'calcs'],
            cond: (_, { value }) => value === 'none',
          },
          {
            target: '.colleague',
            actions: ['assignDiscountType', 'assignColleagueDiscountPercentage', 'assignNoDiscountFixed', 'assignNoDiscountCode', 'calcs'],
            cond: (_, { value }) => value === 'colleague',
          },
          {
            target: '.percentage',
            actions: ['assignDiscountType', 'assignNoDiscountPercentage', 'assignNoDiscountFixed', 'assignNoDiscountCode', 'calcs'],
            cond: (_, { value }) => value === 'percentage',
          },
          {
            target: '.fixed',
            actions: ['assignDiscountType', 'assignNoDiscountPercentage', 'assignNoDiscountFixed', 'assignNoDiscountCode', 'calcs'],
            cond: (_, { value }) => value === 'fixed',
          },
          {
            target: '.code',
            actions: ['assignDiscountType', 'assignNoDiscountPercentage', 'assignNoDiscountFixed', 'assignNoDiscountCode', 'calcs'],
            cond: (_, { value }) => value === 'code',
          },
        ],
      },
    },
    estimateAnnualDemand: {
      initial: 'none',
      states: {
        none: {},
        annualElectricityDemandKwh: {},
        annualElectricityCost: {},
        numberOfOccupants: {},
      },
      on: {
        ANNUAL_ELECTRICITY_DEMAND_KWH: [
          {
            target: '.annualElectricityDemandKwh',
            actions: ['assignAnnualElectricityDemandKwh', 'assignEstimatedElectricityDemandKwh', 'assignIsNotNumOccupants', 'calcs'],
            cond: (_, { value }) => value && value >= 0,
          },
          {
            target: '.annualElectricityCost',
            actions: ['assignAnnualElectricityDemandKwh', 'assignEstimatedElectricityDemandKwh', 'assignIsNotNumOccupants', 'calcs'],
            cond: (c, { value }) => c.annualElectricityCost && value >= 0,
          },
          {
            target: '.annualElectricityCost',
            actions: ['assignAnnualElectricityDemandKwh', 'assignEstimatedElectricityDemandKwh', 'assignIsNotNumOccupants', 'calcs'],
            cond: (context, { currentComparison }) => context.comparisons[currentComparison].annualElectricityCost > 0,
          },
          {
            target: '.numberOfOccupants',
            actions: ['assignAnnualElectricityDemandKwh', 'assignEstimatedElectricityDemandKwh', 'assignIsNumOccupants', 'calcs'],
            cond: c => c.numberOfOccupants > 0,
            meta: { isNumOccupants: true },
          },
          {
            target: '.numberOfOccupants',
            actions: ['assignAnnualElectricityDemandKwh', 'assignEstimatedElectricityDemandKwh', 'assignIsNumOccupants', 'calcs'],
            cond: (context, { currentComparison }) => context.comparisons[currentComparison].numberOfOccupants > 0,
            meta: { isNumOccupants: true },
          },
          {
            target: '.none',
            actions: ['assignAnnualElectricityDemandKwh', 'assignEstimatedElectricityDemandKwh', 'assignIsNotNumOccupants', 'calcs'],
          },
        ],
        ANNUAL_ELECTRICITY_COST: [
          {
            target: '.annualElectricityDemandKwh',
            actions: ['assignAnnualElectricityCost', 'assignEstimatedElectricityDemandKwh', 'assignIsNotNumOccupants', 'calcs'],
            cond: (context, { currentComparison }) => context.comparisons[currentComparison].annualElectricityDemandKwh > 0,
          },
          {
            target: '.annualElectricityDemandKwh',
            actions: ['assignAnnualElectricityCost', 'assignEstimatedElectricityDemandKwh', 'assignIsNotNumOccupants', 'calcs'],
            cond: (c, { value }) => c.annualElectricityDemandKwh && value >= 0,
          },
          {
            target: '.annualElectricityCost',
            actions: ['assignAnnualElectricityCost', 'assignEstimatedElectricityDemandKwh', 'assignIsNotNumOccupants', 'calcs'],
            cond: (_, { value }) => value && value >= 0,
          },
          {
            target: '.numberOfOccupants',
            actions: ['assignAnnualElectricityCost', 'assignEstimatedElectricityDemandKwh', 'assignIsNumOccupants', 'calcs'],
            cond: (_, { value }) => value && value >= 0,
          },
          {
            target: '.numberOfOccupants',
            actions: ['assignAnnualElectricityCost', 'assignEstimatedElectricityDemandKwh', 'assignIsNumOccupants', 'calcs'],
            cond: (context, { currentComparison }) => context.comparisons[currentComparison].numberOfOccupants > 0,
          },
          {
            target: '.none',
            actions: ['assignAnnualElectricityCost', 'assignEstimatedElectricityDemandKwh', 'assignIsNotNumOccupants', 'calcs'],
          },
        ],
        NUMBER_OF_OCCUPANTS: [
          {
            target: '.annualElectricityDemandKwh',
            actions: ['assignNumberOfOccupants', 'assignEstimatedElectricityDemandKwh', 'assignIsNotNumOccupants', 'calcs'],
            cond: (context, { currentComparison }) => context.comparisons[currentComparison].annualElectricityDemandKwh > 0,
          },
          {
            target: '.annualElectricityDemandKwh',
            actions: ['assignNumberOfOccupants', 'assignEstimatedElectricityDemandKwh', 'assignIsNotNumOccupants', 'calcs'],
            cond: (c, { value }) => c.annualElectricityDemandKwh && value >= 0,
          },
          {
            target: '.annualElectricityCost',
            actions: ['assignNumberOfOccupants', 'assignEstimatedElectricityDemandKwh', 'assignIsNotNumOccupants', 'calcs'],
            cond: (c, { value }) => c.annualElectricityCost && value >= 0,
          },
          {
            target: '.numberOfOccupants',
            actions: ['assignNumberOfOccupants', 'assignEstimatedElectricityDemandKwh', 'assignIsNumOccupants', 'calcs'],
            cond: (_, { value }) => value && value >= 0,
          },
          {
            target: '.none',
            actions: ['assignNumberOfOccupants', 'assignEstimatedElectricityDemandKwh', 'assignIsNotNumOccupants', 'calcs'],
          },
        ],
      },
    },
    additionalProducts: {
      initial: 'idle',
      states: {
        idle: {
          on: {
            ADDITIONAL_PRODUCT_ADD: { target: 'idle', actions: ['assignAdditionalProductAddNew', 'calcs'] },
            ADDITIONAL_PRODUCT_DELETE: { target: 'idle', actions: ['assignAdditionalProductDelete', 'calcs'] },
            ADDITIONAL_PRODUCT_QUANTITY: { target: 'idle', actions: ['assignAdditionalProductQuantity', 'calcs'] },
            ADDITIONAL_PRODUCT_SELECT: { target: 'idle', actions: ['assignAdditionalProductSelected', 'calcs'] },
            ADDITIONAL_PRODUCT_UNSELECT: { target: 'idle', actions: ['assignAdditionalProductUnselected', 'calcs'] },
          },
        },
      },
    },
    battery: {
      initial: 'idle',
      states: {
        idle: {
          on: {
            BATTERY_ADD: { target: 'selecting' },
            BATTERY_DELETE: {
              target: 'idle',
              actions: ['assignBatteryDelete', 'determineExportRate', 'calculateG99', 'assignAllRoofCalcs', 'calcs'],
            },
          },
        },
        selecting: {
          on: {
            BATTERY_DELETE: {
              target: 'idle',
              actions: ['assignBatteryDelete', 'determineExportRate', 'calculateG99', 'assignAllRoofCalcs', 'calcs'],
            },
            BATTERY_SELECT: {
              target: 'selected',
              actions: ['assignBatterySelected', 'determineExportRate', 'calculateG99', 'assignAllRoofCalcs', 'calcs'],
            },
          },
        },
        selected: {
          on: {
            BATTERY_DELETE: {
              target: 'idle',
              actions: ['assignBatteryDelete', 'determineExportRate', 'calculateG99', 'assignAllRoofCalcs', 'calcs'],
            },
            BATTERY_SELECT: {
              target: 'selected',
              actions: ['assignBatterySelected', 'determineExportRate', 'calculateG99', 'assignAllRoofCalcs', 'calcs'],
            },
            BATTERY_UNSELECT: {
              target: 'selected',
              actions: ['assignBatteryUnselected', 'determineExportRate', 'calculateG99', 'assignAllRoofCalcs', 'calcs'],
            },
          },
        },
      },
    },
    ev: {
      initial: 'idle',
      states: {
        idle: {
          on: {
            EV_READY: {
              target: 'evReady',
              actions: ['assignEvInstallationType', 'calcs'],
            },
            EV_FULL_INSTALL: {
              target: 'fullInstall',
              actions: ['assignEvInstallationType', 'calcs'],
            },
          },
        },
        evReady: {
          on: {
            EV_NONE: {
              target: 'idle',
              actions: ['assignEvInstallationType', 'calcs'],
            },
            EV_FULL_INSTALL: {
              target: 'fullInstall',
              actions: ['assignEvInstallationType', 'calcs'],
            },
          },
        },
        fullInstall: {
          on: {
            EV_NONE: {
              target: 'idle',
              actions: ['assignEvInstallationType', 'calcs'],
            },
            EV_READY: {
              target: 'evReady',
              actions: ['assignEvInstallationType', 'calcs'],
            },
            EV_FILTER_SOCKET_TYPE: {
              target: 'fullInstall',
              actions: ['assignEvFilterSocketType', 'calcs'],
            },
            EV_FILTER_CABLE_LENGTH: {
              target: 'fullInstall',
              actions: ['assignEvFilterCableLength', 'calcs'],
            },
            EV_FILTER_INCLUDE_RFID: {
              target: 'fullInstall',
              actions: ['assignEvFilterIncludeRfid', 'calcs'],
            },
            EV_FILTER_CONNECTION_TYPE: {
              target: 'fullInstall',
              actions: ['assignEvFilterConnectionType', 'calcs'],
            },
            EV_OLEV_GRANT: {
              target: 'fullInstall',
              actions: ['assignEvOlevGrant', 'calcs'],
            },
            EV_CHARGE_POINT: {
              target: 'fullInstall',
              actions: ['assignEvChargePoint', 'calcs'],
            },
          },
        },
      },
    },
    paymentType: {
      initial: 'none',
      states: {
        none: {},
        oneOff: {},
        eonPay: {},
        inFull: {},
        ghgScheme: {},
      },
      on: {
        PAYMENT_TYPE: [
          { target: '.oneOff', actions: ['assignPaymentType', 'calcs'], cond: (_, { value }) => value === 'oneOff' },
          { target: '.eonPay', actions: ['assignPaymentType', 'calcs'], cond: (_, { value }) => value === 'eonPay' },
          { target: '.inFull', actions: ['assignPaymentType', 'calcs'], cond: (_, { value }) => value === 'inFull' },
          { target: '.ghgScheme', actions: ['assignPaymentType', 'calcs'], cond: (_, { value }) => value === 'ghgScheme' },
        ],
      },
    },
    overrideG99: {
      initial: 'suggested',
      states: {
        no: {},
        yes: {},
        suggested: {},
      },
      on: {
        OVERRIDE_G99_DECISION: [
          {
            target: '.no',
            cond: (c, { currentComparison, value }) => currentComparison === comparison && value === 'no',
          },
          {
            target: '.yes',
            cond: (c, { currentComparison, value }) => currentComparison === comparison && value === 'yes',
          },
          {
            target: '.suggested',
            cond: (c, { currentComparison, value }) => currentComparison === comparison && value === 'suggested',
          },
        ],
      },
    },
    roofs: {
      type: 'parallel',
      states: {
        roof1: roofImageState(comparison, 0),
        roof2: roofImageState(comparison, 1),
        roof3: roofImageState(comparison, 2),
        roof4: roofImageState(comparison, 3),
      },
    },
  },
  on: {
    ADD_ROOF: makeComparisonAction(comparison, ['assignAddRoof', 'assignAllRoofCalcs', 'calcs']),
    AZIMUTH_ELEVATION: makeComparisonAction(comparison, ['assignAzimuthElevation', 'assignAllRoofCalcs', 'calculateG99', 'calcs']),
    BATTERY_INVERTER_TOGGLE: makeComparisonAction(comparison, [
      'assignBatteryInverterToggle',
      'updateFromPimComparison',
      'calculateG99',
      'calcs',
    ]),
    BATTERY_CAPACITY_TOGGLE: makeComparisonAction(comparison, [
      'assignBatteryCapacityToggle',
      'updateFromPimComparison',
      'calculateG99',
      'calcs',
    ]),
    EDIT_COMPARISON_NAME: makeComparisonAction(comparison, 'assignEditComparisonName'),
    EDIT_ROOF_NAME: makeComparisonAction(comparison, 'assignEditRoofName'),
    ENERGY_CONSUMPTION_PROFILE: makeComparisonAction(comparison, ['assignEnergyConsumptionProfile', 'calcs']),
    EXPECTED_SCAFFOLD_COST: makeComparisonAction(comparison, ['assignExpectedScaffoldCost', 'calcs']),
    FLAT_ROOF: makeComparisonAction(comparison, ['assignFlatRoof', 'assignAllRoofCalcs', 'calcs']),
    IMPORT_RATE: makeComparisonAction(comparison, ['assignImportRate', 'assignEstimatedElectricityDemandKwh', 'calcs']),
    INCLUDE_COOLING_OFF_WAIVER: { actions: ['assignIncludeCoolingOffWaiver'] },
    LATITUDE: { actions: ['assignLatitude', 'calcs'] },
    LNUMBER: { actions: ['assignLNumber'] },
    METER_TYPE: {
      target: '.battery.idle',
      actions: ['assignMeterType', 'assignAllRoofCalcs', 'updateFromPimComparison', 'calcs'],
      cond: condComparisonMatch(comparison),
    },
    NARRATIVE: makeComparisonAction(comparison, ['assignNarrative']),
    NUMBER_OF_PANELS_ELEVATION: makeComparisonAction(comparison, [
      'assignNumberOfPanelsElevation',
      'assignAllRoofCalcs', // updates number of panels in the pimComparison
      'updateFromPimComparison', // updates the inverter changes back into the app
      'assignAllRoofCalcs', // re-assign roof calcs after pimComparison changes (e.g. new inverter)
      'calculateG99',
      'calcs',
    ]),
    POSTCODE: { actions: ['assignPostcode', 'assignMcsZoneFromPostcode', 'assignAllRoofCalcs'] },
    REMOVE_ROOF: makeComparisonAction(comparison, [
      'assignRemoveRoof',
      'assignAllRoofCalcs',
      'updateFromPimComparison',
      'assignAllRoofCalcs',
      'calculateG99',
      'calcs',
    ]),
    ROOF_SUNPATH: makeComparisonAction(comparison, ['assignRoofSunpath', 'assignAllRoofCalcs', 'calculateG99', 'calcs']),
    SAVE_COMPARISON_NAME: makeComparisonAction(comparison, 'assignSaveComparisonName'),
    SAVE_ROOF_NAME: makeComparisonAction(comparison, 'assignSaveRoofName'),
    SELECT_INVERTER: makeComparisonAction(comparison, ['assignSolarPvInverter', 'assignAllRoofCalcs', 'calculateG99', 'calcs']),
    SELECT_PANEL: makeComparisonAction(comparison, [
      'assignSolarPvPanel',
      'assignAllRoofCalcs',
      'updateFromPimComparison',
      'assignAllRoofCalcs',
      'calculateG99',
      'calcs',
    ]),
    SELECT_SOLAR_PACKAGE: makeComparisonAction(comparison, [
      'assignSolarPvPackage',
      'determineExportRate',
      'updateFromPimComparison',
      'assignAllRoofCalcs',
      'updateFromPimComparison',
      'assignAllRoofCalcs',
      'calculateG99',
      'calcs',
    ]),
    SELECT_ROOF_FIXING: makeComparisonAction(comparison, [
      'assignRoofFixing',
      'updateFromPimComparison',
      'assignAllRoofCalcs',
      'updateFromPimComparison',
      'assignAllRoofCalcs',
      'calculateG99',
      'calcs',
    ]),
    SLOPE_ELEVATION: makeComparisonAction(comparison, [
      'assignSlopeElevation',
      'assignAllRoofCalcs',
      'updateFromPimComparison',
      'assignAllRoofCalcs',
      'calculateG99',
      'calcs',
    ]),
    SUNPATH_ANALYSIS_POSSIBLE: { actions: ['assignSunpathAnalysisPossible'] },
    UNSELECT_INVERTER: makeComparisonAction(comparison, ['unassignSolarPvInverter', 'assignAllRoofCalcs', 'calculateG99', 'calcs']),
    UNSELECT_PANEL: makeComparisonAction(comparison, [
      'unassignSolarPvPanel',
      'assignAllRoofCalcs',
      'updateFromPimComparison',
      'assignAllRoofCalcs',
      'calculateG99',
      'calcs',
    ]),
    UNSELECT_SOLAR_PACKAGE: makeComparisonAction(comparison, [
      'unassignSolarPvPackage',
      'determineExportRate',
      'updateFromPimComparison',
      'assignAllRoofCalcs',
      'calculateG99',
      'calcs',
    ]),
    UNSELECT_ROOF_FIXING: makeComparisonAction(comparison, [
      'unassignRoofFixing',
      'updateFromPimComparison',
      'assignAllRoofCalcs',
      'calculateG99',
      'calcs',
    ]),
    USER_ID: { actions: ['assignUserId'] },
  },
});

const surveyMachine = Machine(
  {
    id: 'survey',
    type: 'parallel',
    context: initialContext(null),
    states: {
      comparison: {
        initial: 'survey',
        states: {
          survey: { ...comparisonState('survey') },
          one: { ...comparisonState('one') },
          two: { ...comparisonState('two') },
          three: { ...comparisonState('three') },
        },
        on: {
          SELECT_SURVEY: { target: '.survey.hist', actions: ['assignCurrentComparison', 'g99Calcs', 'assignAllRoofCalcs', 'calcs'] },
          SELECT_COMPARISON_ONE: { target: '.one.hist', actions: ['assignCurrentComparison', 'g99Calcs', 'assignAllRoofCalcs', 'calcs'] },
          SELECT_COMPARISON_TWO: { target: '.two.hist', actions: ['assignCurrentComparison', 'g99Calcs', 'assignAllRoofCalcs', 'calcs'] },
          SELECT_COMPARISON_THREE: {
            target: '.three.hist',
            actions: ['assignCurrentComparison', 'g99Calcs', 'assignAllRoofCalcs', 'calcs'],
          },
          RENAME_COMPARISON: { actions: 'assignRenameComparison' },
        },
      },
      mode: {
        initial: 'brandNewPimSurvey',
        states: {
          brandNewPimSurvey: {},
          // same opportunity as current pim session, just use the pimSession
          restartPimSession: {},
          // not a requote, load the opportunity.pim_survey
          restartPimSurvey: {},
          // not a requote, load and convert from opportunity.survey
          restartOldSurvey: {},
          // requote from quote
          requotingQuote: {},
        },
        on: {
          RESTART_PIM_SESSION: { target: '.restartPimSession', actions: ['restartPimSession'] },
          RESTART_PIM_SURVEY: { target: '.restartPimSurvey', actions: ['restartPimSurvey', 'assignIsRequotingFalse'] },
          RESTART_OLD_SURVEY: { target: '.restartOldSurvey', actions: ['restartOldSurvey', 'assignIsRequotingFalse'] },
          QUOTE_REQUOTE: { target: '.requotingQuote', actions: ['requotingQuote', 'assignIsRequotingFalse'] },
        },
      },
      calculationSettings: {
        initial: 'contracted',
        states: {
          contracted: {
            on: {
              CALCULATION_SETTINGS_EXPAND: { target: 'expanded' },
              ENERGY_INFLATION_RATE: { actions: ['assignEnergyInflationRate', 'calcs'] },
              RPI: { actions: ['assignRpi', 'calcs'] },
              EXPORT_RATE: { actions: ['assignExportRate', 'calcs'] },
              CUSTOM_EXPORT_RATE_YES: { actions: ['enableCustomExportRate'] },
              CUSTOM_EXPORT_RATE_NO: { actions: ['disableCustomExportRate', 'determineExportRate', 'calcs'] },
            },
          },
          expanded: {
            on: {
              CALCULATION_SETTINGS_CONTRACT: { target: 'contracted' },
              ENERGY_INFLATION_RATE: { actions: ['assignEnergyInflationRate', 'calcs'] },
              RPI: { actions: ['assignRpi', 'calcs'] },
              EXPORT_RATE: { actions: ['assignExportRate', 'calcs'] },
              CUSTOM_EXPORT_RATE_YES: { actions: ['enableCustomExportRate'] },
              CUSTOM_EXPORT_RATE_NO: { actions: ['disableCustomExportRate', 'determineExportRate', 'calcs'] },
            },
          },
        },
      },
      customerIsEligibleForLowerVatRate: {
        // initial: 'none',
        // UKSQA-1333 - Set customerIsEligibleForLowerVatRate to 'yes' for all and hide field
        initial: 'yes',
        states: {
          none: {},
          no: {},
          yes: {},
        },
        on: {
          CUSTOMER_IS_ELIGIBLE_FOR_LOWER_VAT_RATE_NO: { target: '.no', actions: 'calcs' },
          CUSTOMER_IS_ELIGIBLE_FOR_LOWER_VAT_RATE_YES: { target: '.yes', actions: 'calcs' },
        },
      },
      leadCategory: {
        initial: 'none',
        states: {
          none: {},
          selected: {},
        },
        on: {
          LEAD_CATEGORY: {
            target: '.selected',
            actions: ['assignLeadCategory', 'determineExportRate', 'calculateG99', 'assignAllRoofCalcs', 'calcs'],
          },
        },
      },
      discount: {
        // deprecated, moved to per comparison
        initial: 'none',
        states: {
          none: {},
        },
      },
      existingGridConnectedPvOnSite: {
        initial: 'no',
        states: {
          no: {
            entry: [
              'resetExistingPvAnnualYieldKwh',
              'resetExistingPvInverterTotalRatedOutputKw',
              'calculateG99',
              'assignAllRoofCalcs',
              'calcs',
            ],
            on: {
              EXISTING_GRID_CONNECTED_PV_ON_SITE_YES: { target: 'yes', actions: ['calculateG99', 'assignAllRoofCalcs', 'calcs'] },
            },
          },
          yes: {
            on: {
              EXISTING_PV_ANNUAL_YIELD_KWH: {
                target: 'yes',
                actions: ['assignExistingPvAnnualYieldKwh', 'calculateG99', 'assignAllRoofCalcs', 'calcs'],
              },
              EXISTING_PV_INVERTER_TOTAL_RATED_OUTPUT_KW: {
                target: 'yes',
                actions: ['assignExistingPvInverterTotalRatedOutputKw', 'calculateG99', 'assignAllRoofCalcs', 'calcs'],
              },
              EXISTING_GRID_CONNECTED_PV_ON_SITE_NO: {
                target: '#survey.existingGridConnectedPvOnSite.no',
                actions: ['calculateG99', 'calculateG99', 'assignAllRoofCalcs', 'calcs'],
              },
            },
          },
        },
      },
      formMode: {
        initial: 'idle',
        states: {
          idle: {},
          editing: {},
        },
        on: {
          FOCUS: { target: '.editing' },
          BLUR: { target: '.idle' },
        },
      },
      // isRosemaryOrSlateRoofPreset: {
      //   initial: 'no',
      //   states: {
      //     no: {},
      //     yes: {},
      //   },
      //   on: {
      //     IS_ROSEMARY_OR_SLATE_ROOF_PRESET_NO: { target: '.no', actions: ['assignIsRosemaryOrSlateRoofPreset', 'calcs'] },
      //     IS_ROSEMARY_OR_SLATE_ROOF_PRESET_YES: { target: '.yes', actions: ['assignIsRosemaryOrSlateRoofPreset', 'calcs'] },
      //   },
      // },
      // roofFixing: {
      //   initial: '',
      // },
      isScaffoldingCostOverridden: {
        initial: 'no',
        states: {
          no: {},
          yes: {
            on: {
              BESPOKE_SCAFFOLDING_COST: {
                actions: ['assignOverriddenScaffoldingCost', 'calcs'],
              },
            },
          },
        },
        on: {
          IS_SCAFFOLDING_COST_OVERRIDDEN_NO: {
            target: '.no',
            actions: ['clearOverriddenScaffoldingCost', 'assignIsScaffoldingCostOverridden', 'clearExpectedScaffoldCostIfBespoke', 'calcs'],
          },
          IS_SCAFFOLDING_COST_OVERRIDDEN_YES: {
            target: '.yes',
            actions: ['assignIsScaffoldingCostOverridden', 'assignExpectedScaffoldCostAsBespoke', 'calcs'],
          },
        },
      },
      isInstallCostOverridden: {
        initial: 'no',
        states: {
          no: {},
          yes: {
            on: {
              BESPOKE_INSTALL_COST: {
                actions: ['assignOverriddenInstallCost', 'calcs'],
              },
            },
          },
        },
        on: {
          IS_INSTALL_COST_OVERRIDDEN_NO: {
            target: '.no',
            actions: ['clearOverriddenInstallCost', 'assignIsInstallCostOverridden', 'calcs'],
          },
          IS_INSTALL_COST_OVERRIDDEN_YES: { target: '.yes', actions: ['assignIsInstallCostOverridden', 'calcs'] },
        },
      },
      isKitCostOverridden: {
        initial: 'no',
        states: {
          no: {},
          yes: {
            on: {
              BESPOKE_KIT_COST: {
                actions: ['assignOverriddenKitCost', 'calcs'],
              },
              BESPOKE_KIT_GS_RATIO: {
                actions: ['assignOverriddenKitGsRatio', 'calcs'],
              },
            },
          },
        },
        on: {
          IS_KIT_COST_OVERRIDDEN_NO: {
            target: '.no',
            actions: ['clearOverriddenKitCost', 'clearOverriddenKitGsRatio', 'assignIsKitCostOverridden', 'calcs'],
          },
          IS_KIT_COST_OVERRIDDEN_YES: { target: '.yes', actions: ['assignIsKitCostOverridden', 'calcs'] },
        },
      },
      mpan: {
        initial: 'none',
        states: {
          none: {
            on: {
              MPAN: { target: 'editing', actions: 'assignMpan', cond: 'condValueIsNotEmptyString' },
            },
          },
          editing: {
            on: {
              MPAN: [
                { target: 'none', actions: 'assignMpan', cond: 'condValueIsEmptyString' },
                { target: 'editing', actions: 'assignMpan' },
              ],
            },
          },
        },
      },
      propertyIsListedBuilding: {
        initial: 'no',
        states: {
          no: {
            on: {
              PROPERTY_IS_LISTED_BUILDING_YES: { target: 'yes', actions: 'assignPropertyIsListedBuilding' },
            },
          },
          yes: {
            on: {
              PROPERTY_IS_LISTED_BUILDING_NO: { target: 'no', actions: 'assignPropertyIsListedBuilding' },
            },
          },
        },
      },
      propertyType: {
        initial: 'none',
        states: {
          none: {},
          selected: {},
        },
        on: {
          PROPERTY_TYPE: { target: '.selected', actions: 'assignPropertyType' },
        },
      },
      trenchingRequirementMeters: {
        on: {
          TRENCHING_REQUIREMENT_METERS: {
            actions: ['assignTrenchingRequirementMeters', 'calcs'],
          },
        },
      },
      isEonCustomer: {
        initial: 'no',
        states: {
          no: {},
          yes: {},
        },
        on: {
          EON_CUSTOMER: [
            { target: '.yes', actions: ['assignEonCustomer'], cond: (_, e) => e.value === '178190001' },
            { target: '.no', actions: ['assignEonCustomer'], cond: (_, e) => e.value === '178190002' },
          ],
        },
      },
    },
    on: {
      COMMENTS: { actions: 'assignComments' },
      SCAFFOLD_COMPLEX: { actions: ['assignScaffoldComplex', 'calcs'] },
      IS_KIT_LOCATION_EXTERNAL: { actions: ['assignIsKitLocationExternal', 'calcs'] },
    },
  },
  surveyMachineOptions
);

export default surveyMachine;
