import * as Yup from 'yup';
import { driveTime } from '@zing/neo-common/dist/redux/opportunity/accessor';
import { FILTER_1_PHASE, FILTER_3_PHASE } from '@zing/neo-common/dist/redux/product/accessor';

export const DEPOSIT_AMOUNT = 250;
export const BETA_USER_IDS = [1, 2, 3, 4, 6, 8, 9, 19];
export const JOHN_WAYNE_USER_ID = 5;
export const EV_FULL_INSTALL = 'Full install';
export const EV_NONE = 'None';
export const EV_READY = 'EV Ready';
export const COLLEAGUE_FIXED_PERCENTAGE = 15;
export const VAT_RATE1 = 0;
export const VAT_RATE2 = 0.2;
export const G99_RATE_LIMIT = 3.68;

export const PROFILE_PATH = '/users/profiles/';

export const MOSTLY_USED_EVENINGS_WEEKENDS = 'Mostly evenings and weekends';
export const MOSTLY_USED_DURING_THE_DAY = 'During the whole day';

export const ECOES_REGISTER = 'https://www.ecoes2.co.uk/';

export const INSTALL_SOLAR_ONLY = { key: 'solar-only', label: 'Solar (only)' };
export const INSTALL_SOLAR_STORAGE = { key: 'solar-storage', label: 'Solar and storage' };
export const INSTALL_STORAGE_BATTERY_RETROFIT = {
  key: 'storage-battery-retrofit',
  label: 'Storage (Battery retrofit)',
};
export const INSTALL_SOLAR_EV = { key: 'solar-ev', label: 'Solar & EV' };
export const INSTALL_SOLAR_EV_READY = { key: 'solar-ev-ready', label: 'Solar & EV ready' };
export const INSTALL_SOLAR_STORAGE_EV = { key: 'solar-storage-ev', label: 'Solar, Storage & EV' };
export const INSTALL_SOLAR_STORAGE_EV_READY = { key: 'solar-storage-ev-ready', label: 'Solar, Storage & EV ready' };
export const INSTALL_STORAGE_EV = { key: 'storage-ev', label: 'Storage & EV' };
export const INSTALL_STORAGE_EV_READY = { key: 'storage-ev-ready', label: 'Storage & EV ready' };
export const INSTALL_EV = { key: 'ev-only', label: 'EV only' };
export const INSTALL_SOLAR_EXCHANGE = { key: 'solar-exchange', label: 'Solar Exchange' };
export const INSTALL_SOLAR_SAVER = { key: 'solar-saver', label: 'Solar Saver' };
export const INSTALL_OM_SYSTEM_CHECK = { key: 'om-system-check', label: 'O&M system check' };
export const INSTALL_OM_MONITORING = { key: 'om-monitoring', label: 'O&M monitoring' };

export const ENERGISE_280 = { key: 'energise-280', value: 'Energise 280' };
export const NHANCE_260W_SOLAR_EDGE = { key: 'nhance-260w-solar-edge', value: 'Nhance 260W Solar Edge' };
export const COMPLEX_INSTALL = 'Complex';
export const PAYMENT_TYPE_ONE_OFF = `£${DEPOSIT_AMOUNT} up front payment with balance after install`;
export const PAYMENT_TYPE_EON_PAY = 'Our range of flexible finance options';
export const PAYMENT_TYPE_IN_FULL = 'Payment in full before install';
export const PAYMENT_TYPE_GHG_SCHEME = 'GHG funding (fully funded)';

export const MAX_NUM_INSTALL_ROOFS = 4;
export const MAX_NUM_PACKAGES = 4;
export const MAX_BATTERY_QTY = 4;
export const MAX_BATTERY_ROWS = 1;

export const DEFAULT_PRICE_PER_UNIT = 13;

// Common validators used in powra
const stringRequiredWhen = (field, equals, message) =>
  Yup.string().when(field, {
    is: equals,
    then: Yup.string().required(message || 'This field is required'),
  });
const notNo = () => Yup.string().notOneOf(['No'], 'STOP_WORK');
const notYes = () => Yup.string().notOneOf(['Yes'], 'STOP_WORK');

export const POWRA = {
  // {
  //   heading: Used as the heading text for popup
  //   questions: The questions and their options. Some questions have a conditionally rendered 'note' field after them
  //   initialValues: Used to set the default state in redux (not used as formik initialValues,use state for that)
  //   validator: Yup validation schema for the form section
  // }
  //
  // NOTE: Initial values must be valid submittable values!!
  onArrival: {
    heading: 'On arrival',
    questions: [
      {
        name: 'siteSafetyInductions',
        label: 'Have you completed any relevant safety inductions for the site?',
        options: ['Yes', 'No', 'NA'],
        goodOption: 'Yes',
        badOption: 'No',
      },
      {
        name: 'siteEmergencyProcedures',
        label: 'Do you know the emergency procedures for the site, including where to go and who to contact?',
        options: ['Yes', 'No', 'NA'],
        goodOption: 'Yes',
        badOption: 'No',
      },
      {
        name: 'unusualEnvironmentConditions',
        label:
          'Is the work location free from any unusual environmental conditions which could impact your work, i.e. ice, heavy rain, strong winds etc?',
        options: ['Yes', 'No'],
        goodOption: 'Yes',
        badOption: 'No',
      },
      {
        name: 'appropriateEquipment',
        label: 'Do you have appropriate equipment to safely transport any  tools/equipment to the work place?',
        options: ['Yes', 'No'],
        goodOption: 'Yes',
        badOption: 'No',
      },
      {
        name: 'opportunityInCalendar',
        label: "Is this appointment scheduled in your calendar? (If 'No', please add a 'Note' as to why)",
        options: ['Yes', 'No'],
        goodOption: 'Yes',
        badOption: 'No',
        note: {
          name: 'opportunityInCalendarNote',
          condition: 'No',
        },
      },
    ],
    initialValues: {
      siteSafetyInductions: 'NA',
      siteEmergencyProcedures: 'NA',
      unusualEnvironmentConditions: 'Yes',
      appropriateEquipment: 'Yes',
      opportunityInCalendar: 'Yes',
      opportunityInCalendarNote: '',
    },
    validator: Yup.object().shape({
      siteSafetyInductions: notNo(),
      siteEmergencyProcedures: notNo(),
      unusualEnvironmentConditions: notNo(),
      appropriateEquipment: notNo(),
      opportunityInCalendarNote: stringRequiredWhen('opportunityInCalendar', 'No'),
    }),
  },
  meetCustomer: {
    heading: 'Meeting the customer',
    questions: [
      {
        name: 'vulnerableInHousehold',
        label: "Are there any vulnerable customers in the household? (If 'Yes', Please provide details.)",
        options: ['Yes', 'No'],
        goodOption: 'No',
        badOption: 'Yes',
        note: {
          name: 'vulnerableInHouseholdNote',
          condition: 'Yes',
        },
      },
      {
        name: 'specialPowerRequirements',
        label: "Are there any special requirements regarding losing power at the property? If 'Yes', enter details below.",
        options: ['Yes', 'No'],
        goodOption: 'No',
        badOption: 'Yes',
        note: {
          name: 'specialPowerRequirementsNote',
          condition: 'Yes',
        },
      },
      {
        name: 'freeFromRiskyPets',
        label:
          "Is the property free from any pets or animals that could put you at risk? (If 'No', then please add a 'Note' regarding the risk)",
        options: ['Yes', 'No'],
        goodOption: 'Yes',
        badOption: 'No',
        note: {
          name: 'freeFromRiskyPetsNote',
          condition: 'No',
        },
      },
      {
        name: 'isCustomerPresentForVisit',
        label: 'Is the householder (or an appointed adult) to be present for the duration of the visit?',
        options: ['Yes', 'No'],
        goodOption: 'Yes',
        badOption: 'No',
      },
      {
        name: 'customerAuthForSurvey',
        label: 'Do you have authorisation from the customer to begin the survey?',
        options: ['Yes', 'No', 'NA'],
        goodOption: 'Yes',
        badOption: 'No',
      },
    ],
    initialValues: {
      vulnerableInHousehold: 'No',
      specialPowerRequirements: 'No',
      freeFromRiskyPets: 'Yes',
      isCustomerPresentForVisit: 'Yes',
      customerAuthForSurvey: 'Yes',
      opportunityInCalendarNote: '',
      vulnerableInHouseholdNote: '',
      specialPowerRequirementsNote: '',
      freeFromRiskyPetsNote: '',
    },
    validator: Yup.object().shape({
      vulnerableInHouseholdNote: stringRequiredWhen('vulnerableInHousehold', 'Yes'),
      specialPowerRequirementsNote: stringRequiredWhen('specialPowerRequirements', 'Yes'),
      freeFromRiskyPetsNote: stringRequiredWhen('freeFromRiskyPets', 'No'),
      isCustomerPresentForVisit: notNo(),
      customerAuthForSurvey: notNo(),
    }),
  },

  startSurvey: {
    heading: 'Starting the survey',
    questions: [
      {
        name: 'familiarWithProcedure',
        label: 'Are you familiar with the procedure or method statement for the task?',
        options: ['Yes', 'No'],
        goodOption: 'Yes',
        badOption: 'No',
      },
      {
        name: 'safeRouteOfAccess',
        label:
          'Have you identified a safe route of access and egress from the property, and is it clear from obstructions/trip hazards/ other work parties?',
        options: ['Yes', 'No'],
        goodOption: 'Yes',
        badOption: 'No',
      },
      {
        name: 'areaSuitablyLit',
        label: 'Is the working area suitably lit and the right temperature for your work?',
        options: ['Yes', 'No'],
        goodOption: 'Yes',
        badOption: 'No',
      },
      {
        name: 'correctToolsAndCondition',
        label: 'Do you have the right PPE and tools for the task and are they in good condition?',
        options: ['Yes', 'No'],
        goodOption: 'Yes',
        badOption: 'No',
      },
      {
        name: 'isWorkAreaSafe',
        label: 'Is each work area tidy, organised and safe to start work?',
        options: ['Yes', 'No'],
        goodOption: 'Yes',
        badOption: 'No',
      },
      {
        name: 'anyAdditionalHazards',
        label: 'Take a moment to look around each work area. Are there any additional hazards which may be hidden or obstructed?',
        options: ['Yes', 'No'],
        goodOption: 'No',
        badOption: 'Yes',
      },
    ],
    initialValues: {
      familiarWithProcedure: 'Yes',
      safeRouteOfAccess: 'Yes',
      areaSuitablyLit: 'Yes',
      correctToolsAndCondition: 'Yes',
      isWorkAreaSafe: 'Yes',
      anyAdditionalHazards: 'No',
    },
    validator: Yup.object().shape({
      familiarWithProcedure: notNo(),
      safeRouteOfAccess: notNo(),
      areaSuitablyLit: notNo(),
      correctToolsAndCondition: notNo(),
      isWorkAreaSafe: notNo(),
      anyAdditionalHazards: notYes(),
    }),
  },

  afterwards: {
    heading: 'Afterwards',
    questions: [
      {
        name: 'asbestosOnSite',
        label:
          "Have you identified any possible asbestos on the site, or is the customer aware of any asbestos on site? (If 'Yes', take photos of the location and add details in the Technical Survey submission.)",
        options: ['Yes', 'No'],
        goodOption: 'No',
        badOption: 'Yes',
      },
    ],
    initialValues: {
      asbestosOnSite: 'No',
    },
    validator: Yup.object().shape({
      asbestosOnSite: notYes(),
    }),
  },
};

export const titleOptions = [
  { value: 'Mr', label: 'Mr' },
  { value: 'Ms', label: 'Ms' },
  { value: 'Mrs', label: 'Mrs' },
  { value: 'Miss', label: 'Miss' },
  { value: 'Dr', label: 'Dr' },
  { value: 'Prof', label: 'Prof' },
  { value: 'Rev', label: 'Rev' },
];

export const MSG_HOUSE_NUMBER_REQUIRED = 'House number / name is required';
export const MSG_HOUSE_NUMBER_MAX_LENGTH = '20 characters maximum';
export const MSG_ADDRESS_LINE_1_REQUIRED = 'Address line 1 is required';
export const MSG_CHANNEL_REQUIRED = 'Channel is required';
export const MSG_CITY = 'City is required';
export const MSG_CITY_REQUIRED = 'City is required';
export const MSG_EMAIL_INVALID = 'Email address is invalid';
export const MSG_EMAIL_REQUIRED = 'Email address is required';
export const MSG_EON_CUSTOMER_REQUIRED = 'E.ON Customer is required';
export const MSG_FIRST_NAME_REQUIRED = 'First name is required';
export const MSG_LAST_NAME_REQUIRED = 'Last name is required';
export const MSG_LAT_REQUIRED = 'Latitude is required';
export const MSG_LEAD_CATEGORY_REQUIRED = 'Customer type is required';
export const MSG_LNG_REQUIRED = 'Longitude is required';
export const MSG_MEETING_STATUS_REQUIRED = 'Meeting status is required';
export const MSG_MEETING_TYPE_REQUIRED = 'Meeting type is required';
export const MSG_MOBILE_NUMERIC = 'Mobile number must be numeric';
export const MSG_MOBILE_REQUIRED = 'Mobile number is required';
export const MSG_MPAN_REQUIRED = 'MPAN is required';
export const MSG_PHONE_NUMERIC = 'Telephone number must be numeric';
export const MSG_PHONE_REQUIRED = 'Telephone number is required';
export const MSG_ROLE_IS_REQUIRED = 'Role is required';
export const MSG_SIGNATURE_IS_REQUIRED = 'Signature is required';

export const durationOptions = [
  '00:15:00',
  '00:30:00',
  '00:45:00',
  '01:00:00',
  '01:15:00',
  '01:30:00',
  '01:45:00',
  '02:00:00',
  '02:15:00',
  '02:30:00',
  '02:45:00',
  '03:00:00',
].map(x => ({ value: x, label: driveTime(x, '') }));

export const travelTimeOptions = [
  '00:15:00',
  '00:30:00',
  '00:45:00',
  '01:00:00',
  '01:15:00',
  '01:30:00',
  '01:45:00',
  '02:00:00',
  '02:15:00',
  '02:30:00',
  '02:45:00',
  '03:00:00',
].map(x => ({ value: x, label: driveTime(x) }));

export const channels = [
  { value: 178190013, label: 'Nissan' },
  { value: 178190011, label: 'Netofex' },
  { value: 178190015, label: 'Rendham' },
  { value: 100000001, label: 'Silicon Calculator' },
  { value: 178190000, label: 'Google Sunroof' },
  { value: 178190014, label: 'Retrofit page' },
  { value: 178190012, label: 'MVF' },
  { value: 178190001, label: 'Leads To You' },
  { value: 178190004, label: 'Leads 2 Trade' },
  { value: 178190005, label: 'Equifax' },
  { value: 178190006, label: 'Face to face (f2f)' },
  { value: 178190017, label: 'Press' },
  { value: 178190018, label: 'Events' },
  { value: 178190007, label: 'Leaflet through door' },
  { value: 178190008, label: 'Referral' },
  { value: 178190002, label: 'Inbound' },
  { value: 178190003, label: 'Self Generated' },
  { value: 178190009, label: 'SelfGen - referral card' },
  { value: 178190010, label: 'Other' },
];

export const SEMI_DETACHED = '178190000';
export const DETACHED_HOUSE = '178190001';
export const END_TERRACE = '178190005';
export const MID_TERRACE = '178190002';
export const BUNGALOW = '178190003';
export const OTHER = '178190004';

export const propertyTypes = [
  { value: SEMI_DETACHED, label: 'Semi detached' },
  { value: DETACHED_HOUSE, label: 'Detached' },
  { value: END_TERRACE, label: 'End terrace' },
  { value: MID_TERRACE, label: 'Mid terrace' },
  { value: BUNGALOW, label: 'Bungalow' },
  { value: OTHER, label: 'Other' },
];

export const meterTypes = [
  { value: '178190000', label: FILTER_1_PHASE },
  { value: '178190001', label: FILTER_3_PHASE },
];

export const convertMeterTypeToApiValue = meterType => {
  const found = meterTypes.find(x => x.label === meterType);
  return found ? found.value : '';
};

// ‘Daytime not at Home’ => 178190000,
// ‘Daytime at Home’     => 178190001,

export const daytimeMapping = {
  [MOSTLY_USED_EVENINGS_WEEKENDS]: 178190000,
  [MOSTLY_USED_DURING_THE_DAY]: 178190001,
};

export const daytimeMappings = [
  { value: '178190000', label: MOSTLY_USED_EVENINGS_WEEKENDS },
  { value: '178190001', label: MOSTLY_USED_DURING_THE_DAY },
];

export const MAX_NUM_ROOFS = 4;
export const MAX_PANELS_PER_ROOF = 13;

const MAX_ADMIN_PERCENTAGE_DISCOUNT = 100;
const MAX_USER_PERCENTAGE_DISCOUNT = 10;

export const getMaxDiscountPercentageLimit = user =>
  user?.role === 'Admin' ? MAX_ADMIN_PERCENTAGE_DISCOUNT : MAX_USER_PERCENTAGE_DISCOUNT;

export const getMaxFixedDiscountLimit = (user, calcs) =>
  user?.role === 'Admin'
    ? calcs?.beforeDiscountTotals?.incVatTotal * (MAX_ADMIN_PERCENTAGE_DISCOUNT / 100)
    : calcs?.beforeDiscountTotals?.incVatTotal * (MAX_USER_PERCENTAGE_DISCOUNT / 100);
