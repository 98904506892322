import * as Yup from 'yup';
import { MORE_THAN, REQUIRED, REQUIRED_NUMBER, REQUIRED_PRODUCT } from '../../../../components/Survey/validate/messages';

const comparisonShape = Yup.object().shape({
  additionalProducts: Yup.array()
    .min(0)
    .of(
      Yup.object().shape({
        product: Yup.object()
          .shape({
            id: Yup.number()
              .typeError(REQUIRED_PRODUCT)
              .required(REQUIRED)
              .moreThan(0, MORE_THAN),
          })
          .required(REQUIRED_PRODUCT),
        quantity: Yup.number()
          .typeError(REQUIRED_NUMBER)
          .required(REQUIRED)
          .moreThan(0, MORE_THAN),
      })
    )
    .required(REQUIRED),
});

const schema = comparison =>
  Yup.object().shape({
    context: Yup.object().shape({
      comparisons: Yup.object().shape({
        [comparison]: comparisonShape,
      }),
    }),
    value: Yup.object().shape({}),
  });

export default schema;
