import { appointmentMatchUrl } from '../../utils/opportunity';
import { appPropTypes } from '../../app-prop-types';
import { connect } from 'react-redux';
import { get } from '@zing/neo-common/dist/lib/safe';
import { PimContext } from '../../pim/survey/contexts/pim-context';
import { PROPERTY_DETAILS, THANK_YOU_MEETING } from '../../utils/paths';
import { startPimSurvey } from '../../redux/pim-session/actions';
import { useContext, useEffect } from 'react';
import { useMachine } from '@xstate/react/lib';
import initialContext from '../../machines/survey.machine.initial-context';
import initialState from '../../redux/pim-session/initialstate';
import PropTypes from 'prop-types';
import session from './session';
import surveyMachine from '../../machines/survey.machine';
import useLocalStorage from '../../hooks/useLocalStorage';

const COMPLETED_LOADING = 'COMPLETED_LOADING';

/**
 * This page has one purpose, it is a landing page from other "entry points" that decides how we should
 * start a PIM Session.
 *
 * Possible entry points
 * - Appointment details page, brand new PIM survey (brandNewPimSurvey)
 * - Appointment details page, same as existing localstorage.pimSession (restartPimSession)
 * - Appointment details page, restart existing opportunity.survey (restartOldSurvey)
 * - Appointment details page, restart existing opportunity.pimSurvey (restartPimSurvey)
 * - Pipeline view appointment (could be anyone of above..) will be determined by appointment page logic
 * - Pipeline requote quote.pim_survey (requote) Doesn't matter where a quote came from, its a quote with the
 *                                               same structure, so we read the values in to the state machine.
 *
 * The reason it is here is that we will need to initialise the state machine withContext depending on the scenario,
 * So, other pages determine the "entry point" scenario and pass it into here.
 */

const PimStartSurvey = ({ history, location, match, startPimSurvey }) => {
  // State variables passed via router
  const locationState = location.state;
  const mode = get(locationState, 'mode');
  const surveyMachineState = get(locationState, 'surveyMachineState');
  // const backUrl = get(locationState, 'backUrl', '');
  const opportunity = get(locationState, 'opportunity', '');
  const meeting = get(locationState, 'meeting', '');
  const user = get(locationState, 'user', '');
  const quote = get(locationState, 'quote', '');

  // console.log('locationState', locationState);
  // console.log('history', history);
  // console.log('location', location);
  // console.log('match', match);

  const thankYouMeetingUsPage = appointmentMatchUrl(match, THANK_YOU_MEETING);
  const surveyPropertyDetailsPage = appointmentMatchUrl(match, ['survey', PROPERTY_DETAILS]);

  // now that we have our 'facts' lets get the session started/restarted
  const { pim } = useContext(PimContext);

  const pimContext = mode === 'restartPimSurvey' ? surveyMachineState : { ...initialContext(pim) };
  const [, pimSessionSet] = useLocalStorage('pimSession', initialState);
  const [, send, service] = useMachine(surveyMachine.withContext(pimContext));

  useEffect(() => {
    const subscription = service.subscribe(state => {
      if (state.event.type === COMPLETED_LOADING) {
        const quotes = state.event.quotes || [];
        // salesConsultant
        // This works the same as it always has, we always use the current user when performing a survey
        // there is a ticket that suggest otherwise, but this is how it was requested to work, and how it works in production on purpose
        // in the future we may change to keep the previous user, i.e. (when requoting)
        const salesConsultant = {
          email: user?.email,
          firstname: user?.firstname,
          id: user?.id,
          lastname: user?.lastname,
          mobile: user?.mobile,
          // eslint-disable-next-line camelcase
          profile_image_filename: user?.profile_image_filename,
          telephone: user?.telephone,
        };
        pimSessionSet({
          ...initialState,
          opportunity: state.event.opportunity,
          meeting: state.event.meeting,
          powra: state.event.opportunity.powra,
          surveyMachine: state,
          salesConsultant,
          quotes,
        });

        history.push(state.event.pathname);
      }
    });
    return subscription.unsubscribe;
  }, []);

  // Must only run once the service is initialised and available
  useEffect(() => {
    switch (mode) {
      case 'brandNewPimSurvey': {
        startPimSurvey();
        session.brandNewPimSurvey(send, opportunity);
        // todo: load powra into session?
        send({ type: COMPLETED_LOADING, opportunity, meeting, pathname: thankYouMeetingUsPage });
        break;
      }
      case 'restartPimSession': {
        session.restartPimSession(send);
        send({ type: COMPLETED_LOADING, opportunity, meeting, pathname: thankYouMeetingUsPage });
        break;
      }
      case 'restartPimSurvey': {
        startPimSurvey();
        session.restartPimSurvey(send);
        send({ type: COMPLETED_LOADING, opportunity, meeting, pathname: thankYouMeetingUsPage });
        break;
      }
      case 'restartOldSurvey': {
        startPimSurvey();
        session.restartOldSurvey(send, opportunity);
        send({ type: COMPLETED_LOADING, opportunity, meeting, pathname: thankYouMeetingUsPage });
        break;
      }
      case 'requote': {
        startPimSurvey();
        session.requote(send, opportunity, meeting, user, quote);
        send({ type: COMPLETED_LOADING, opportunity, meeting, pathname: surveyPropertyDetailsPage });
        break;
      }
    }
  }, [service]);

  return null;
};

PimStartSurvey.propTypes = {
  location: PropTypes.shape(appPropTypes.locationPropTypes).isRequired,
  history: PropTypes.shape(appPropTypes.historyPropTypes).isRequired,
  match: PropTypes.shape(appPropTypes.matchPropTypes).isRequired,
};

export default connect(() => {}, { startPimSurvey })(PimStartSurvey);
