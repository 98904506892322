import { isSchemaValid, validate } from '../validate';
import additionalProducts from '../tabs/additional-products/schema';
import battery from '../tabs/battery/schema';
import energyDetails from '../tabs/energy-details/schema';
import g99 from '../tabs/g99/schema';
import propertyDetails from '../tabs/property-details/schema';
import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';
import solarPv from '../tabs/solar-pv/schema';
import settings from '../tabs/settings/schema';
import { UserContext } from './user-context';

let errors = {};
let tabs = {};
const touched = {};
const hasError = field => errors[field];
const isTouched = field => touched[field] !== undefined;
const isInvalid = field => hasError(field) && isTouched(field);
const error = field => (errors[field] === undefined ? undefined : errors[field]);
const touch = field => {
  touched[field] = true;
};

// todo: Move all of this into the state machine..
// it will know when to validate
// it will know which tabs to validate
//
// context.tabs.propertyDetails.isRequired
// context.tabs.propertyDetails.isValid

// Context
const SurveyValidationContext = React.createContext({ service: undefined });

// Hook
const useSurveyValidation = () => {
  const context = useContext(SurveyValidationContext);
  if (context === undefined) {
    throw new Error('useSurveyValidation must be used within a SurveyValidationContextProvider');
  }
  return context;
};

// Provider
export const SurveyValidationContextProvider = ({ service, children }) => {
  const user = useContext(UserContext);
  useEffect(() => {
    // monitor service and validate on transition
    const subscription = service.subscribe(state => {
      const { currentComparison } = state.context;
      const values = { context: state.context, value: state.value };
      const validated = validate(currentComparison, values, user);

      errors = {};
      // now tie the individual field errors into touched fields
      if (Array.isArray(validated.inner)) {
        validated.inner.forEach(validationError => {
          const field = validationError.path;
          if (touched[field]) {
            // eslint-disable-next-line prefer-destructuring
            errors[field] = validationError.errors[0];
          } else {
            delete errors[field];
          }
        });
      }
      // console.log('');
      // console.log('Validation executed');
      // console.log('values', values);
      // console.log('validated', validated.inner);
      // console.log('touched', touched);
      // console.log('errors', errors);
      // console.log('');
      // console.log('value.comparison.survey.battery', values.value.comparison.survey.battery);
      // console.log('values.context.comparisons.survey.battery', values.context.comparisons.survey.battery);

      // what about the tabs? have they validated?
      tabs = {
        solarPv: isSchemaValid(solarPv(currentComparison), values),
        additionalProducts: isSchemaValid(additionalProducts(currentComparison), values),
        battery: isSchemaValid(battery(currentComparison), values),
        energyDetails: isSchemaValid(energyDetails(currentComparison), values),
        g99: isSchemaValid(g99(currentComparison), values),
        propertyDetails: isSchemaValid(propertyDetails, values),
        settings: isSchemaValid(settings(currentComparison, user, values), values),
      };

      tabs.canProgress =
        tabs.propertyDetails && tabs.energyDetails && tabs.solarPv && tabs.battery && tabs.additionalProducts && tabs.settings;
      // console.log('tabs', tabs);
      // console.log('additionalProducts', state.context.comparisons[currentComparison].additionalProducts);
    });

    return subscription.unsubscribe;
  }, [service]);

  return (
    <SurveyValidationContext.Provider
      value={{
        service,
        validator: {
          error,
          hasError,
          isInvalid,
          isTouched,
          touch,
          tabs,
          errors,
        },
      }}
    >
      {children}
    </SurveyValidationContext.Provider>
  );
};

SurveyValidationContextProvider.propTypes = {
  service: PropTypes.shape({
    subscribe: PropTypes.func.isRequired,
  }).isRequired,
  children: PropTypes.shape({}).isRequired,
};

export default useSurveyValidation;
