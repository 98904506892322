import React, { useContext } from 'react';
import Actions from './actions';
import Checkbox from '../../hollywood-xstate/checkbox';
import ComparisonSelection from '../../components/comparison-selection';
import Logo from '@zing/neo-common/dist/components/Logo';
import Select from '../../hollywood-xstate/select';
import Textarea from '../../hollywood-xstate/textarea';
import { BreakdownPanel } from './BreakdownPanel';
import { Col, Grid } from '@zing/neo-common/dist/components/Grid';
import { getContextCurrentComparison } from '../../../../machines/survey.machine';
import { getNumber } from '@zing/neo-common/dist/lib/safe';
import { PAYMENT_TYPE_EON_PAY, PAYMENT_TYPE_IN_FULL, PAYMENT_TYPE_ONE_OFF } from '../../../../config';
import { PimSurveyMachineContext } from '../../contexts/survey-context';
import { ReactComponent as PaymentIcon } from './payment-icon.svg';
import { UserContext } from '../../contexts/user-context';

import './payment-options.scss';

const PaymentOptions = () => {
  const user = useContext(UserContext);
  const { current, send } = useContext(PimSurveyMachineContext);
  const { currentComparison } = current.context;
  const currentComparisonContext = getContextCurrentComparison(current);
  const {
    calcs,
    includeCoolingOffWaiver,
    narrative,
    narrativeDefaultText,
    paymentType = 'none',
    sunpathAnalysisPossible,
  } = currentComparisonContext;

  const canSeePricing = user && user.role !== 'External';
  const totalCost = getNumber(calcs, 'paymentOptions.totalCostIncVat');
  const deposit = getNumber(calcs, 'paymentOptions.depositIncVat');
  const totalDueAfterInstallation = getNumber(calcs, 'paymentOptions.totalDueAfterInstallation');

  // let paymentOptions = [{ label: PAYMENT_TYPE_GHG_SCHEME, value: 'ghgScheme' }]; // UKSQA-1429 - Disable GHG Funding (Fully funded) option
  let paymentOptions = [];
  if (user && user?.role !== 'External') {
    paymentOptions = [
      { label: PAYMENT_TYPE_ONE_OFF, value: 'oneOff' },
      { label: PAYMENT_TYPE_EON_PAY, value: 'eonPay' },
      { label: PAYMENT_TYPE_IN_FULL, value: 'inFull' },
      ...paymentOptions,
    ];
  }
  return (
    <>
      <ComparisonSelection />
      <main className="payment-options" data-testid="paymentOptions">
        <div className="title">
          <PaymentIcon />
          <h1>Payment Options</h1>
        </div>
        <Grid gutters="sm">
          <Col>
            <label htmlFor="paymentType">Payment options</label>
            <Select
              name="paymentType"
              options={paymentOptions}
              onChange={(_, value) => {
                send({ type: 'PAYMENT_TYPE', currentComparison, value });
              }}
              value={paymentType}
            />
          </Col>

          <Col width="12">
            <label htmlFor="narrative" className="heavy">
              <strong>Narrative description for quote</strong>
            </label>
            {narrativeDefaultText && (
              <div className="narrative_default_text">
                <div>{narrativeDefaultText.intro}</div>
                <ul>
                  {narrativeDefaultText.lines.map(x => (
                    <li>{x}</li>
                  ))}
                </ul>
              </div>
            )}
            <Textarea
              className="narrative"
              name="narrative"
              rows={7}
              value={narrative}
              onChange={(_, value) => {
                send({ type: 'NARRATIVE', currentComparison, value });
              }}
            />
          </Col>
        </Grid>

        <p className="light sm">
          This should describe the layout of installation in detail.
          <br /> This text will appear on the quote document.
        </p>

        <p>
          <Checkbox
            name="includeCoolingOffWaiver"
            label="Include cooling off waiver?"
            onChange={(_, value) => {
              send({ type: 'INCLUDE_COOLING_OFF_WAIVER', currentComparison, value });
            }}
            checked={includeCoolingOffWaiver === true}
          />
        </p>
        <p>
          <Checkbox
            name="sunpathAnalysisPossible"
            label="Sun path analysis not possible/estimated at this time"
            onChange={(_, value) => {
              send({ type: 'SUNPATH_ANALYSIS_POSSIBLE', currentComparison, value });
            }}
            checked={sunpathAnalysisPossible === true}
          />
        </p>
        <div className="breakdown-col">
          <div className="breakdown-col-img">
            <Logo />
          </div>
          <div className="breakdown-col-content">
            {canSeePricing && (
              <BreakdownPanel
                paymentType={paymentType}
                totalCost={totalCost}
                deposit={deposit}
                totalDueAfterInstallation={totalDueAfterInstallation}
              />
            )}

            <Actions current={current} />
          </div>
        </div>
      </main>
    </>
  );
};

PaymentOptions.propTypes = {};

export default PaymentOptions;
