import * as types from './actionTypes';
import addSeconds from 'date-fns/add_seconds';
import { api } from '@zing/neo-common/dist/lib/api';
import { isAccessTokenLogin, makeLoginOptions } from './utils';
import { newUserCheck } from '../../components/forms/LoginForm/new-user-check';
import { get } from '@zing/neo-common/dist/lib/safe';
import { userDetails } from '@zing/neo-common/dist/redux/user/actions';
import { fetchPresenter } from '../presenter/actions';
import { fetchQuoteEmailTemplate } from '../quote-email/actions';
import { fetchOpportunitiesFromDate } from '../opportunities/actions';
import { refreshAppData, refreshResources } from '../settings/settings';
import { threeWeeksAgo } from '../../utils/three-weeks-ago';

export const newUserLogin = () => ({ type: types.AUTH_NEW_USER });

export const handleLoginSuccess = (response, dispatch, values, appApi) => {
  const { data } = response;
  const action = {
    type: types.AUTH_SUCCESS,
    data: {
      ...data,
      email: values.email || '',
      expires: addSeconds(new Date().getTime(), data.expires_in || 0),
      loggedInViaToken: isAccessTokenLogin(values),
    },
  };
  dispatch(action);

  if (isAccessTokenLogin(values)) {
    // dispatch(checkTokenNewUser());
  }
  dispatch(userDetails());
  dispatch(fetchPresenter());
  dispatch(fetchQuoteEmailTemplate());
  dispatch(fetchOpportunitiesFromDate(threeWeeksAgo()));
  // todo: sort this mess out
  // We're using timeout to guess completed at the moment
  setTimeout(() => {
    dispatch(refreshAppData('Completed'));
    dispatch(refreshResources('Completed'));
  }, 2000);

  if (appApi) {
    // eslint-disable-next-line no-console
    console.log('Triggering appApi.auth.loginSuccess');
    appApi.auth.loginSuccess(action.data);
  }
};

export const handleLoginError = (response, dispatch, appApi) => {
  let actionPayload = null;
  if (response) {
    const { data = null, status = null, statusText = '' } = response;
    actionPayload = {
      error: data.error,
      status,
      statusText,
    };
  }
  const action = {
    type: types.AUTH_ERROR,
    data: actionPayload,
  };
  dispatch(action);
  if (appApi) {
    appApi.auth.loginError(action.data);
  }
};

/**
 * Login can be done in two ways. Both of which trigger an API call.
 *
 * 1. email/password in values array, makes a call to '/oauth/authorize' and processes result
 * 2. access_token in values array, makes a call to '/oauth/token' and processes result
 *    This is a one time call token passed via the url to the app
 */
export const login = (values, user, appApi) => dispatch =>
  // eslint-disable-next-line
  new Promise((resolve, reject) => {
    if (!isAccessTokenLogin(values) && newUserCheck(values.email, user)) {
      dispatch(newUserLogin());
    }
    dispatch({ type: types.AUTH });
    dispatch({ type: types.AUTH_BEGIN });

    // tell the app machine we are logging in

    if (appApi) {
      appApi.auth.loginBegin();
    }

    return api(makeLoginOptions(values))
      .then(response => {
        handleLoginSuccess(response, dispatch, values, appApi);
        resolve();
      })
      .catch(error => {
        const response = get(error, 'response', null);
        handleLoginError(response, dispatch, appApi);
        reject(response);
      });
  });

export const logout = () => dispatch => {
  dispatch({ type: types.AUTH_SIGN_OUT });
};

export const check = () => api();
