import { FILTER_1_PHASE, FILTER_3_PHASE } from '@zing/neo-common/dist/redux/product/accessor';
import { daytimeMapping, EV_FULL_INSTALL, EV_READY, MOSTLY_USED_DURING_THE_DAY, MOSTLY_USED_EVENINGS_WEEKENDS } from '../../config';
import { packageMappings } from './package-mappings';
import { customerTypesAllowedInApp } from '@zing/neo-common/dist/config';
import { getBoolean } from '@zing/neo-common/dist/lib/safe';

/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */

const currentComparison = 'survey';

const sendGenerics = (send, opportunity) => {
  send({ type: 'G99_APPLICATION', value: opportunity.g99_application });
  send({ type: 'LATITUDE', value: opportunity.lat });
  send({ type: 'LNUMBER', value: opportunity.lnumber || '' });
  send({ type: 'MPAN', value: opportunity.mpan || '' });
  send({ type: 'POSTCODE', currentComparison, value: opportunity.postcode || '' });
  send({ type: 'USER_ID', value: opportunity.user_id });

  const leadCategory = opportunity?.lead_category;
  if (leadCategory) {
    // is it an allowed lead category?
    const isAllowed = customerTypesAllowedInApp.find(x => Number(x.value) === Number(leadCategory));
    if (isAllowed) {
      send({ type: 'LEAD_CATEGORY', value: leadCategory });
    }
  }

  // EON Customer (send if set)
  const eonCustomer = String(opportunity?.eon_customer || '');
  if (eonCustomer.length > 0) {
    send({ type: 'EON_CUSTOMER', value: eonCustomer });
  }
};

const loadOldSurveyValues = (send, opportunity) => {
  const { survey } = opportunity;
  if (survey.eligible_customer === 'Yes') {
    send('CUSTOMER_IS_ELIGIBLE_FOR_LOWER_VAT_RATE_YES');
  }
  if (survey.eligible_customer === 'No') {
    send('CUSTOMER_IS_ELIGIBLE_FOR_LOWER_VAT_RATE_NO');
  }
  if (survey.is_listed_building === 'Yes') {
    send('PROPERTY_IS_LISTED_BUILDING_YES');
  }
  if (survey.is_listed_building === 'No') {
    send('PROPERTY_IS_LISTED_BUILDING_NO');
  }
  send({ type: 'PROPERTY_TYPE', value: survey.property_type });
  // Energy details tab
  if (survey.grid_connected_pv_on_site === 'Yes') {
    send('EXISTING_GRID_CONNECTED_PV_ON_SITE_YES');
    send({ type: 'EXISTING_PV_ANNUAL_YIELD_KWH', value: survey.existing_pv_annual_yield_kwh });
    send({
      type: 'EXISTING_PV_INVERTER_TOTAL_RATED_OUTPUT_KW',
      value: survey.existing_pv_inverter_total_rated_output_kw,
    });
  }
  if (survey.grid_connected_pv_on_site === 'No') {
    send('EXISTING_GRID_CONNECTED_PV_ON_SITE_NO');
  }
  if (survey.meter_type) {
    send({ type: 'METER_TYPE', currentComparison, value: survey.meter_type });
  }

  // MPAN (From the survey) overrides from the opportunity
  send({ type: 'MPAN', value: survey.mpan });
  send({ type: 'IMPORT_RATE', currentComparison, value: survey.customer_electricity_price });

  if (survey.electricity_mostly_used === MOSTLY_USED_DURING_THE_DAY) {
    send({
      type: 'ENERGY_CONSUMPTION_PROFILE',
      currentComparison,
      value: String(daytimeMapping[MOSTLY_USED_DURING_THE_DAY]),
    });
  }
  if (survey.electricity_mostly_used === MOSTLY_USED_EVENINGS_WEEKENDS) {
    send({
      type: 'ENERGY_CONSUMPTION_PROFILE',
      currentComparison,
      value: String(daytimeMapping[MOSTLY_USED_EVENINGS_WEEKENDS]),
    });
  }

  if (survey.annual_electricity_demand_kwh) {
    send({ type: 'ANNUAL_ELECTRICITY_DEMAND_KWH', currentComparison, value: survey.annual_electricity_demand_kwh });
  }
  if (survey.annual_electricity_cost) {
    send({ type: 'ANNUAL_ELECTRICITY_COST', currentComparison, value: survey.annual_electricity_cost });
  }
  if (survey.number_of_occupants) {
    send({ type: 'NUMBER_OF_OCCUPANTS', currentComparison, value: survey.number_of_occupants });
  }

  // todo: additional products
  // batteries
  // solar PV
};

const brandNewPimSurvey = (send, opportunity) => {
  sendGenerics(send, opportunity);
};
const restartPimSession = send => {
  send('RESTART_PIM_SESSION');
};

const restartPimSurvey = send => {
  send('RESTART_PIM_SURVEY');
};
const restartOldSurvey = (send, opportunity) => {
  send('RESTART_OLD_SURVEY');
  sendGenerics(send, opportunity);
  loadOldSurveyValues(send, opportunity);
};

// eslint-disable-next-line no-nested-ternary
const readProdId = product => (product?.prodId ? product.prodId : product?.prod_id ? product.prod_id : undefined);
// eslint-disable-next-line no-nested-ternary
const readQuantity = item => (item?.quantity ? Number(item.quantity) : item?.qty ? Number(item.qty) : 0);

const requote = (send, opportunity, meeting, user, quote) => {
  // console.log('opportunity', opportunity);
  // console.log('meeting', meeting);
  // console.log('user', user);
  // console.log('quote', quote);
  send('QUOTE_REQUOTE');
  sendGenerics(send, opportunity);

  // EON Customer
  if (quote.eon_customer) {
    send({ type: 'EON_CUSTOMER', value: quote.eon_customer.toString() });
  }

  // L000322
  // Property details tab
  if (quote.lead_category) {
    send({ type: 'LEAD_CATEGORY', value: quote.lead_category });
  }
  // if (quote.pim_survey?.customerIsEligibleForLowerVatRate) {
  send('CUSTOMER_IS_ELIGIBLE_FOR_LOWER_VAT_RATE_YES');
  // } else if (quote.pim_survey?.customerIsEligibleForLowerVatRate === false) {
  //   send('CUSTOMER_IS_ELIGIBLE_FOR_LOWER_VAT_RATE_NO');
  // }
  if (quote.listed_conservation === 'Yes') {
    send('PROPERTY_IS_LISTED_BUILDING_YES');
  }
  if (quote.listed_conservation === 'No') {
    send('PROPERTY_IS_LISTED_BUILDING_NO');
  }
  send({ type: 'PROPERTY_TYPE', value: quote.property_type });

  // Energy details tab
  if (quote.grid_connected_pv_on_site === 'Yes') {
    send('EXISTING_GRID_CONNECTED_PV_ON_SITE_YES');
    send({ type: 'EXISTING_PV_ANNUAL_YIELD_KWH', value: quote.existing_pv_yield_kwh });
    send({
      type: 'EXISTING_PV_INVERTER_TOTAL_RATED_OUTPUT_KW',
      value: quote.size_of_existing_pv_kw,
    });
  } else {
    send('EXISTING_GRID_CONNECTED_PV_ON_SITE_NO');
  }

  if (Number(quote.meter_type) === 178190000) {
    send({ type: 'METER_TYPE', currentComparison, value: FILTER_1_PHASE });
  }
  if (Number(quote.meter_type) === 178190001) {
    send({ type: 'METER_TYPE', currentComparison, value: FILTER_3_PHASE });
  }
  send({ type: 'MPAN', value: quote.mpan || '' });
  send({ type: 'IMPORT_RATE', currentComparison, value: quote.customer_electricity_price });
  send({ type: 'ENERGY_CONSUMPTION_PROFILE', currentComparison, value: String(quote.energy_consumption_profile) });
  send({ type: 'ANNUAL_ELECTRICITY_DEMAND_KWH', currentComparison, value: quote.annual_electricity_demand });
  send({ type: 'ANNUAL_ELECTRICITY_COST', currentComparison, value: quote.annual_electricity_cost });
  send({ type: 'NUMBER_OF_OCCUPANTS', currentComparison, value: quote.number_of_occupants });

  // Solar PV tab
  // We currently do NOT store the package prodId in the quote, I will add this to the quote_pim_survey, so we can get it in the future
  if (readProdId(quote.pim_survey?.package)) {
    send({ type: 'SELECT_SOLAR_PACKAGE', currentComparison, prodId: readProdId(quote.pim_survey.package) });
  } else if (quote?.package_id) {
    const packageIdToFind = Number(quote?.package_id || 0);
    // do our mapping to a PLYTIX_PROD_ID

    // testing with L008670
    const mapping = packageMappings.find(x => Number(x.id) === packageIdToFind);
    if (mapping) {
      send({ type: 'SELECT_SOLAR_PACKAGE', currentComparison, prodId: mapping.plytixProdId });
    }
  }

  if (quote.panel) {
    send({ type: 'SELECT_PANEL', currentComparison, prodId: readProdId(quote.panel) });
  }
  // Roofs..
  [1, 2, 3, 4].forEach(roofIndex => {
    const index = roofIndex - 1;
    if (quote[`roof_name_${roofIndex}`]) {
      if (roofIndex > 1) {
        send({ type: 'ADD_ROOF', currentComparison });
      }
      send({ type: 'SLOPE_ELEVATION', currentComparison, index, value: quote[`slope_elevation_${roofIndex}`] });
      send({ type: 'AZIMUTH_ELEVATION', currentComparison, index, value: quote[`azimuth_elevation_${roofIndex}`] });
      send({ type: 'NUMBER_OF_PANELS_ELEVATION', currentComparison, index, value: quote[`number_of_panels_elevation_${roofIndex}`] });

      // make shaded segments safe!
      // previous bug converted empty shaded segments to 1 shaded
      let shadedSegments = quote[`shaded_segments_${roofIndex}`];
      if (shadedSegments) {
        const segments = shadedSegments.split(',');
        if (segments.length) {
          shadedSegments = segments.map(Number);
        }
      }
      send({ type: 'ROOF_SUNPATH', currentComparison, index, shadedSegments });
    }
  });

  if (quote.inverter) {
    send({ type: 'SELECT_INVERTER', currentComparison, prodId: readProdId(quote.inverter) });
  }
  if (quote.pim_survey?.trenchingRequirementMeters) {
    send({ type: 'TRENCHING_REQUIREMENT_METERS', value: quote.pim_survey.trenchingRequirementMeters });
  } else {
    // OLD QUOTE TRENCHING!
    const productForCrm = quote?.product_costs?.productForCrm;
    if (Array.isArray(productForCrm) && productForCrm.length > 0) {
      const foundTrenching = productForCrm.find(x => x?.id === 'TRENCHING-INSTALL');
      if (foundTrenching) {
        send({ type: 'TRENCHING_REQUIREMENT_METERS', value: Number(foundTrenching.qty) });
      }
    }
  }

  if (quote.roof_fixing) {
    send({ type: 'SELECT_ROOF_FIXING', currentComparison, prodId: readProdId(quote.roof_fixing) });
  }
  // if (quote.is_rosemary_or_slate_roof_preset === 'Yes') {
  //   send({ type: 'IS_ROSEMARY_OR_SLATE_ROOF_PRESET_YES', currentComparison, value: true });
  // } else {
  //   send({ type: 'IS_ROSEMARY_OR_SLATE_ROOF_PRESET_NO', currentComparison, value: false });
  // }
  if (readProdId(quote.pim_survey?.expectedScaffoldCost)) {
    send({ type: 'EXPECTED_SCAFFOLD_COST', currentComparison, prodId: readProdId(quote.pim_survey.expectedScaffoldCost) });
  }
  if (quote.scaffold_complex) {
    send({ type: 'SCAFFOLD_COMPLEX', value: quote.scaffold_complex.toLowerCase() });
  }
  if (quote.scaffold_complex) {
    send({ type: 'COMMENTS', value: quote.comments || '' });
  }
  if (quote.external_kit_location) {
    send({ type: 'IS_KIT_LOCATION_EXTERNAL', currentComparison, value: quote.external_kit_location.toLowerCase() });
  }
  // Bespoke Pricing
  if (quote.pim_survey.isScaffoldingCostOverridden === true) {
    send({
      type: `IS_SCAFFOLDING_COST_OVERRIDDEN_YES`,
      currentComparison,
      value: true,
    });
    send({ type: `BESPOKE_SCAFFOLDING_COST`, currentComparison, value: quote.pim_survey.overriddenScaffoldingCost });
  }
  if (quote.pim_survey.isInstallCostOverridden === true) {
    send({ type: `IS_INSTALL_COST_OVERRIDDEN_YES`, currentComparison, value: true });
    send({ type: `BESPOKE_INSTALL_COST`, currentComparison, value: quote.pim_survey.overriddenInstallCost });
  }
  if (quote.pim_survey.isKitCostOverridden === true) {
    send({ type: `IS_KIT_COST_OVERRIDDEN_YES`, currentComparison, value: true });
    send({ type: `BESPOKE_KIT_COST`, currentComparison, value: quote.pim_survey.overriddenKitCost });
    send({ type: `BESPOKE_KIT_GS_RATIO`, currentComparison, value: quote.pim_survey.overriddenKitGsRatio });
  }

  // Battery Tab
  if (Array.isArray(quote.batteries) && quote.batteries.length && readProdId(quote.batteries[0]?.battery)) {
    send('BATTERY_ADD');
    send({ type: 'BATTERY_SELECT', currentComparison, prodId: readProdId(quote.batteries[0]?.battery) });
  }

  // EV Charging Tab
  // console.log('quote', quote);

  if (quote?.pim_survey?.evInstallationType === EV_READY) {
    send({ type: 'EV_READY', currentComparison, value: EV_READY });
  }
  if (quote?.pim_survey?.evInstallationType === EV_FULL_INSTALL) {
    send({ type: 'EV_FULL_INSTALL', currentComparison, value: EV_FULL_INSTALL });
    if (quote.charge_point && readProdId(quote.charge_point)) {
      send({ type: 'EV_CHARGE_POINT', currentComparison, prodId: readProdId(quote.charge_point) });
    }
    if (quote?.pim_survey?.evOlevGrant === 'yes') {
      send({ type: 'EV_OLEV_GRANT', currentComparison, value: 'yes' });
    }
    if (quote?.pim_survey?.evOlevGrant === 'no') {
      send({ type: 'EV_OLEV_GRANT', currentComparison, value: 'no' });
    }
  }

  // Additional Products Tab
  if (Array.isArray(quote.add_on_products) && quote.add_on_products.length) {
    quote.add_on_products.forEach((item, index) => {
      const { product = {} } = item;
      const quantity = readQuantity(item);
      if (index > 1) {
        send({ type: 'ADDITIONAL_PRODUCT_ADD', currentComparison });
      }
      send({ type: 'ADDITIONAL_PRODUCT_SELECT', currentComparison, prodId: readProdId(product), index });
      send({ type: 'ADDITIONAL_PRODUCT_QUANTITY', currentComparison, quantity, index });
    });
  }

  // L000322
  // Discounting
  if (quote.payment_options.discountType === 'Percentage') {
    send({ type: 'DISCOUNT_TYPE', currentComparison, value: 'percentage' });
    send({ type: 'DISCOUNT_PERCENTAGE', currentComparison, value: quote.payment_options.discountPercentage });
  }
  if (quote.payment_options.discountType === 'Colleague') {
    send({ type: 'DISCOUNT_TYPE', currentComparison, value: 'colleague' });
    send({ type: 'DISCOUNT_PERCENTAGE', currentComparison, value: quote.payment_options.discountPercentage });
  }

  // Payment Type
  send({ type: 'PAYMENT_TYPE', currentComparison, value: quote.payment_options.paymentType });

  // Export rate
  if (getBoolean(quote.custom_export_rate)) {
    send({ type: 'CUSTOM_EXPORT_RATE_YES' });
  } else {
    send({ type: 'CUSTOM_EXPORT_RATE_NO' });
  }
};

export default {
  brandNewPimSurvey,
  requote,
  restartOldSurvey,
  restartPimSession,
  restartPimSurvey,
  sendGenerics,
};
