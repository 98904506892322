import PimComparison from '@zing/neo-common/dist/accessors/pim-comparison';
import { ENHANCED_EXPORT_RATE } from '@zing/neo-common/dist/config';

export const initialRoof = index => ({
  annualGenerationYear: undefined,
  annualYieldKwhElevation: undefined,
  azimuthElevation: undefined,
  compassBearing: 'S',
  flatRoof: 'no',
  individualPanelPowerW: undefined,
  installedCapacity: undefined,
  kwhKwpElevation: undefined,
  numberOfPanelsElevation: undefined,
  numberOfShadedSegmentsElevation: 0,
  postcodeZoneId: undefined,
  ratedPowerKw: undefined,
  roofName: `Roof ${index}`,
  roofNameEditing: `Roof ${index}`,
  shadedSegments: [],
  shadingFactorElevation: 1,
  slopeElevation: undefined,
  sunpathSaved: false,
  uploadError: undefined,
  image: undefined,
});

const initialComparison = (name, pim, visible = false) => ({
  additionalProducts: [],
  annualElectricityCost: undefined,
  annualElectricityDemandKwh: undefined,
  battery: undefined,
  batteryCapacityToggle: 'All',
  batteryInverterToggle: 'Both',
  canAddEvCharger: undefined,
  canShowBatteryInverterFilter: false,
  canShowBatteryPackageSelection: false,
  discountPercentage: 0,
  discountFixed: 0,
  discountFixedAllowed: false,
  discountType: 'none',
  energyConsumptionProfile: undefined,
  estimatedElectricityDemandKwh: 0,
  evChargePoint: undefined,
  evFilterCableLength: undefined,
  evFilterConnectionType: undefined,
  evFilterIncludeRfid: undefined,
  evFilterSocketType: undefined,
  evInstallationType: undefined,
  evOlevGrant: undefined,
  expectedScaffoldCost: undefined,
  hasSolarPV: false,
  importRate: 16,
  includeCoolingOffWaiver: false,
  inverter: undefined,
  isBatteryOnly: true,
  isNumOccupants: false,
  isRequoting: false,
  // isRosemaryOrSlateRoofPreset: false,
  isInstallCostOverridden: false,
  isScaffoldingCostOverridden: false,
  isKitCostOverridden: false,
  meterType: undefined,
  name,
  nameEditing: name,
  narrative: '',
  narrativeDefaultText: {
    intro: '',
    lines: [],
  },
  numberOfOccupants: undefined,
  overriddenInstallCost: 0,
  overriddenScaffoldingCost: 0,
  overriddenKitCost: 0,
  overriddenKitGsRatio: 1,
  panel: undefined,
  paymentType: undefined,
  pimComparison: new PimComparison(pim),
  roofFixing: undefined,
  roofs: [initialRoof(1)],
  solarPvPackage: undefined,
  sunpathAnalysisPossible: false,
  systemTotalInstallPvKwh: 0,
  systemTotalInstallPvKwhCombined: 0,
  systemTotalPeakDcPower: 0,
  totalPanels: 0,
  trenchingRequirementMeters: 0,
  visible,
  dynamic: {
    products: {
      selected: {},
      required: {},
    },
    calcs: {
      g99: {},
    },
  },
});

const initialContext = pim => ({
  version: {
    context: 1, // this is version one of this machines context format, it could change later..
  },
  comments: undefined,
  currentComparison: 'survey',
  leadCategory: undefined,
  eonCustomer: '',
  energyInflationRate: 0.068,
  existingPvAnnualYieldKwh: undefined,
  existingPvInverterTotalRatedOutputKw: undefined,
  exportRate: ENHANCED_EXPORT_RATE(true, false, undefined),
  customExportRate: false,
  firstYearDegradation: 0.03,
  g99Application: undefined,
  latitude: undefined,
  lnumber: undefined,
  mpan: undefined,
  postcode: undefined,
  postcodeZoneId: undefined,
  propertyType: undefined,
  propertyIsListedBuilding: undefined,
  rpi: 0.025,
  externalKitLocation: 'no',
  scaffoldComplex: 'no',
  subsequentYears: 0.007,
  userId: undefined,
  comparisons: {
    survey: { ...initialComparison('Reference', pim, true) },
    one: { ...initialComparison('Comparison One', pim) },
    two: { ...initialComparison('Comparison Two', pim) },
    three: { ...initialComparison('Comparison Three', pim) },
  },
});

export default initialContext;
