import additionalProductsSchema from '../tabs/additional-products/schema';
import batterySchema from '../tabs/battery/schema';
import energyDetails from '../tabs/energy-details/schema';
import propertyDetails from '../tabs/property-details/schema';
import solarPv from '../tabs/solar-pv/schema';
import settings from '../tabs/settings/schema';

const getValidationSchema = (currentComparison, user, values) =>
  additionalProductsSchema(currentComparison)
    .concat(batterySchema(currentComparison))
    .concat(energyDetails(currentComparison))
    // .concat(evChargingSchema)
    // .concat(g99Schema)
    .concat(propertyDetails)
    .concat(settings(currentComparison, user, values))
    .concat(solarPv(currentComparison));

export const validate = (currentComparison, values, user) => {
  const schema = getValidationSchema(currentComparison, user, values);
  try {
    schema.validateSync(values, { abortEarly: false, context: values });
    return {};
  } catch (error) {
    return error;
  }
};

export const isSchemaValid = (schema, values) => {
  try {
    return schema.isValidSync(values, { abortEarly: false, context: values });
  } catch (error) {
    return false;
  }
};

export const isValid = (currentComparison, values) => {
  const schema = getValidationSchema(currentComparison);
  try {
    return isSchemaValid(schema, values);
  } catch (error) {
    return false;
  }
};
